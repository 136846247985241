import React from "react";
import { Box, Typography, Button, styled } from "@mui/material";
import { closeIcon, right } from "./assets";

interface Props {
    onClose: () => void;
    headingPopup:String; 
    titlePopup:String; 
    subTitlePopup : String;
    buttonText:String;
    onOpen: () => void;
}

const CustomPopup = (props: Props) => {
    const { onClose, headingPopup, titlePopup, subTitlePopup, buttonText, onOpen } = props;
    
    return (
        <MainWrapper>
            <Box style={{width:"100%"}}>
            <Box className="paper">
                <Box className="mainLayout">
                    <Typography className="headerTitle">{headingPopup}</Typography>
                    <Box className="closeIconBox" onClick={onClose}>
                        <img src={closeIcon.default} className="closeIcon" />
                    </Box>
                </Box>
                <Box className="divider"></Box>
                <Box className="success">
                    <img src={right.default} />
                </Box>
                <Box className="boxLayout">
                    <Typography className="content1">
                        {titlePopup}
                    </Typography>
                    <Typography className="content2">
                        {subTitlePopup}
                    </Typography>
                </Box>
                <Box className="divider"></Box>
                <Box className="popupContent">
                    <Button data-test-id="move-btn" className="button" onClick={onOpen}>
                        {buttonText}
                    </Button>
                </Box>
            </Box>
            </Box>
        </MainWrapper>
    );
};

export default CustomPopup;

export const MainWrapper = styled(Box)(({ theme }) => ({
    "& .paper": {
        marginTop: "283px",
        marginLeft: "auto",
        marginRight: "auto",
        width: "600px",
        backgroundColor: "#fff",
        borderRadius: "8px 8px 32px 8px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        gap: "24px",
    },
    "& .mainLayout": {
        width: "92%",
        height: "32px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginTop: "24px",
    },
    "& .headerTitle": {
        color: "#000000",
        fontFamily: "Urbanist",
        fontSize: "24px",
        fontWeight: "700",
        display: "flex",
        alignItems: "center",
    },
    "& .closeIconBox": {
        width: "32px",
        height: "32px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    "& .closeIcon": {
        width: "14px",
        height: "14px",
    },
    "& .success": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "22px",
    },
    "& .boxLayout": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "10px",
        padding: "0 40px",
    },
    "& .content1": {
        textAlign:"center",
        color: "#0F172A",
        fontFamily: "Urbanist",
        fontSize: "20px",
        fontWeight: "700",
    },
    "& .content2": {
        textAlign:"center",
        color: "#0F172A",
        fontFamily: "Urbanist",
        fontSize: "18px",
        fontWeight: "400",
    },
    "& .popupContent": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        alignSelf: "end",
        padding: "0 22px 22px 0",
    },
    "& .button": {
        padding: "16px 30.5px",
        backgroundColor: "#044352",
        color: "#FFFFFF",
        fontFamily: "Urbanist",
        fontSize: "16px",
        fontWeight: "700",
        textTransform:"none",
        width:"120px",
        "&:hover": {
            backgroundColor: "#044352",
        }
    },
    "& .divider":{
        width:"100%",
        border:"1px solid #E2E8F0"
    },

    [theme.breakpoints.down('md')]: {
        "& .paper": {
            width: "90%",
            marginTop: "20px",
            padding: "0 10px",
        },
        "& .boxLayout": {
            padding: "0 20px",
        },
        "& .headerTitle": {
            fontSize: "20px",
        },
        "& .content1": {
            fontSize: "18px",
        },
        "& .content2": {
            fontSize: "16px",
        },
        "& .button": {
            fontSize: "14px",
            padding: "12px 24px",
        },
    },

    [theme.breakpoints.down('sm')]: {
        "& .paper": {
            width: "95%",
            marginTop: "10px",
            padding: "0 8px",
        },
        "& .boxLayout": {
            padding: "0 16px",
        },
        "& .headerTitle": {
            fontSize: "18px",
        },
        "& .content1": {
            fontSize: "16px",
        },
        "& .content2": {
            fontSize: "14px",
        },
        "& .button": {
            fontSize: "12px",
            padding: "10px 20px",
        },
    },
}));
