import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import React from "react";
import { SelectChangeEvent } from '@mui/material/Select';

interface ContryCodeResponse {
    message?: string;
    error?: string;
    country_codes?: string[];
}

// Customizable Area End

export const configJSON = require("./config");
export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    errorMsg: string;
    referenceImage: string | File | null | undefined;
    referenceImageError: string;
    countryCodeData: ContryCodeResponse;
    selectedCountryCode: string;
    userBirthDate: string | undefined;
    birthDateError: string;
    gender: string[];
    selectedGender: string;
    department: string[];
    selectedDepartment: string;
    competencies: string[];
    selectedCompetencies: string[];
    userType: string[];
    selectedUserType: string;
    createUserClicked: boolean;
    firstName: string;
    firstNameError: string;
    lastName: string;
    lastNameError: string;
    userEmail: string;
    emailError: string;
    userContactNumber: string;
    contactError: string;
    staffId: string;
    userDesignation: string;
    companyName: string;
    jobDescription: string;
    // Customizable Area End

}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class UserCreationController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    dateInputRef: null | React.RefObject<HTMLInputElement> = null;
    getAllCountryCodeApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];

        this.state = {
            errorMsg: "",
            referenceImage: null,
            referenceImageError: "",
            countryCodeData: {},
            selectedCountryCode: "91",
            userBirthDate: "",
            birthDateError: "",
            gender: ["Male", "Female", "Other"],
            selectedGender: "",
            department: ["IT Department", "Manufacturing", "HR Department"],
            selectedDepartment: "",
            competencies: ["Core Competencies", "Behavioural Competencies", "Leadership Competencies"],
            selectedCompetencies: [],
            userType: ["Candidate", "Employee", "HR SubAdmin"],
            selectedUserType: "",
            createUserClicked: false,
            firstName: "",
            firstNameError: "",
            lastName: "",
            lastNameError: "",
            userEmail: "",
            emailError: "",
            userContactNumber: "",
            contactError: "",
            staffId: "",
            userDesignation: "",
            companyName: "",
            jobDescription: ""
        };
        this.dateInputRef = React.createRef<HTMLInputElement>();
        // Customizable Area End
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId === this.getAllCountryCodeApiCallId) {
                this.countryCodeApiResponse(responseJson)
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
        this.getAllCountryCodeApiCall();
    }

    countryCodeApiResponse = (responseJson: ContryCodeResponse) => {
        if (responseJson && !responseJson.error) {
            this.setState({ countryCodeData: responseJson })
        } else if (responseJson && responseJson.error) {
            this.setState({
                errorMsg: responseJson.error
            });
        }
    }

    getAllCountryCodeApiCall = () => {
        const headers = {
            "Content-Type": "application/json",
        };
        const getMsgForCountryCode = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getAllCountryCodeApiCallId = getMsgForCountryCode.messageId;
        getMsgForCountryCode.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getCountryCodeApiEndPoint}`
        );
        getMsgForCountryCode.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        getMsgForCountryCode.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );
        runEngine.sendMessage(getMsgForCountryCode.id, getMsgForCountryCode);
    }

    handleCreateUser = () => {
        this.setState({ createUserClicked: true })

        let errorInFirst = !this.state.firstName;
        let errorInLast = !this.state.lastName;
        let errorInEmail = !this.state.userEmail;
        let errorIncontact = !this.state.userContactNumber;
        let errorInStaffId = !this.state.staffId;
        let errorInBirthDate = !this.state.userBirthDate;
        let errorInSelectedGender = !this.state.selectedGender;
        let errorInUserDesignation = !this.state.userDesignation;
        let errorInSelectedDepartment = !this.state.selectedDepartment;
        let errorInCompanyName = !this.state.companyName;
        let errorInUserType = !this.state.selectedUserType;
        let errorInCompetencies = !this.state.selectedCompetencies;
        let errorInJobDescription = !this.state.jobDescription;

        this.firstNameValidation();
        this.lastNameValidation();
        this.validationEmail();
        this.phoneNumberValidation();
        this.birthDateValidation();

        if (errorInFirst || this.state.firstNameError ||
            errorInLast || this.state.lastNameError ||
            errorInEmail || this.state.emailError ||
            errorIncontact || this.state.contactError ||
            errorInStaffId ||
            errorInBirthDate ||
            errorInSelectedGender ||
            errorInUserDesignation ||
            errorInSelectedDepartment ||
            errorInCompanyName ||
            errorInUserType ||
            errorInCompetencies ||
            errorInJobDescription
        ) {
            return;
        }
    }

    firstNameValidation = () => {
        let firstNameError = "";
        const alphabeticRegex = /^[A-Za-z\s]+$/;

        if ((this.state.firstName.length < 3) || (this.state.firstName.length > 100)) {
            firstNameError = configJSON.namelongError
        }
        else if (!alphabeticRegex.test(this.state.firstName)) {
            firstNameError = configJSON.nameAlphabaticError;
        }
        this.setState({ firstNameError })
    }

    lastNameValidation = () => {
        let lastNameError = "";
        const alphabeticRegex = /^[A-Za-z\s]+$/;

        if ((this.state.lastName.length < 3) || (this.state.lastName.length > 100)) {
            lastNameError = configJSON.namelongError
        }
        else if (!alphabeticRegex.test(this.state.lastName)) {
            lastNameError = configJSON.nameAlphabaticError;
        }
        this.setState({ lastNameError })
    }

    validationEmail = () => {
        let emailError = "";
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

        if (!this.state.userEmail) {
            emailError = "This field is required.";
        } else if (!emailRegex.test(this.state.userEmail)) {
            emailError = "Please enter a valid email address.";
        }

        this.setState({ emailError });
    }

    phoneNumberValidation = () => {
        let contactError = '';
        const contactRegex = /^\d{6,10}$/;

        if (!this.state.userContactNumber) {
            contactError = 'This field is required.';
        } else if (!contactRegex.test(this.state.userContactNumber)) {
            contactError = 'Contact number must be between 6 and 10 digits and contain only numbers.';
        }

        this.setState({ contactError });
    }

    birthDateValidation = () => {
        if (!this.state.userBirthDate) {
            this.setState({ birthDateError: configJSON.errorText });
        } else {
            const inputDate = new Date(this.state.userBirthDate);
            const today = new Date();
            const thresholdDate = new Date();
            thresholdDate.setFullYear(today.getFullYear() - 18);

            if (inputDate.getTime() > thresholdDate.getTime()) {
                this.setState({ birthDateError: configJSON.errorDateOfBirthText });
            } else {
                this.setState({ birthDateError: '' });
            }
        }
    }

    getMaxDateOfBirthDate = () => {
        const today = new Date();
        const year = today.getFullYear() - 18;
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const day = String(today.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        const acceptedFormats = ['image/jpeg', 'image/png'];
        const maxFileSize = 5 * 1024 * 1024;

        if (file) {
            if (acceptedFormats.includes(file.type) && file.size < maxFileSize) {
                const fileReader = new FileReader();

                fileReader.onload = () => {
                    this.setState({
                        referenceImage: fileReader.result as string,
                        referenceImageError: '',
                    });
                };

                fileReader.readAsDataURL(file);
            } else {
                if (!acceptedFormats.includes(file.type)) {
                    this.setState({
                        referenceImageError: 'Please upload png and jpeg/jpg file only.',
                    });
                }
                if (file.size > maxFileSize) {
                    this.setState({
                        referenceImageError: 'Please upload file with 5mb only.',
                    });
                }
            }
        }
    };

    handleFirstName = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({ firstName: event.target.value }, () => { this.firstNameValidation() })
    }

    handleLastName = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({ lastName: event.target.value }, () => { this.lastNameValidation() })
    }

    handleCountryCodeChange = (event: SelectChangeEvent<string>) => {
        this.setState({ selectedCountryCode: event.target.value });
    };

    handleUserEmailChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({ userEmail: event.target.value }, () => { this.validationEmail() })
    }

    handlePhoneNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ userContactNumber: event.target.value }, () => { this.phoneNumberValidation() })
    }

    handleStaffIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ staffId: event.target.value })
    }

    handleBirthDateChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({ userBirthDate: event.target.value })
    }

    handleGenderChange = (event: SelectChangeEvent<string>) => {
        this.setState({ selectedGender: event.target.value });
    }

    handleDesignationchange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({ userDesignation: event.target.value })
    }

    handleDepartmentChange = (event: SelectChangeEvent<string>) => {
        this.setState({ selectedDepartment: event.target.value });
    }

    handleCompanyNameChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({ companyName: event.target.value })
    }

    handleUserChange = (event: SelectChangeEvent<string>) => {
        this.setState({ selectedUserType: event.target.value });
    }

    handleCompetenciesChange = (event: SelectChangeEvent<string[]>) => {
        const { value } = event.target;
        this.setState({
            selectedCompetencies: typeof value === 'string' ? value.split(',') : value,
        });
    };

    handleJobDescriptionChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({ jobDescription: event.target.value.length > 1000 ? this.state.jobDescription : event.target.value })
    }
    
    backToDashBoard = () => {
        const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
        navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "AllUsersDashboard");
        navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigateMsg); 
    }
    // Customizable Area End

}