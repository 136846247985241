import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { getStorageData } from "framework/src/Utilities";

interface ViewCompanyData {
    message?: string;
    error?: string;
    organisation_details?: {
        data?: {
            id: string;
            type: string;
            attributes: {
                employee_size: string;
                current_company_stage: string;
                company_culture: string;
                company_industry: string;
                organizational_keywords: string;
                job_style: string;
                document_url: string;
                document_filename: string;
                document_size: number;
                country: string;
                region: string;
                company_name: string;
            }
        }
    }
}
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    errorMsg: string;
    showCompanyData: ViewCompanyData;
    loader: boolean;
    token: string;
    // Customizable Area End

}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class ViewCompanyDetailsController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    showCompanyApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];

        this.state = {
            token: "",
            errorMsg: "",
            showCompanyData: {},
            loader: false
        };

        // Customizable Area End
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId === this.showCompanyApiCallId) {
                this.handleShowCompanyApiResponse(responseJson)
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();

        const signInResponse = await getStorageData("signInResponse");
        const parsedSignInResponse = JSON.parse(signInResponse)
        this.setState({
            token: parsedSignInResponse.meta?.token,
        })

        this.viewCompanyApiCall();
    }

    handleShowCompanyApiResponse = (responseJson: ViewCompanyData) => {
        if (responseJson && !responseJson.error) {
            this.setState({ showCompanyData: responseJson, loader: false })
        } else if (responseJson && responseJson.error) {
            this.setState({
                errorMsg: responseJson.error,
                loader: false
            });
        }
    }

    viewCompanyApiCall = () => {
        this.setState({ loader: true })
        const headers = {
            "Content-Type": "application/json",
            "token": this.state.token
        };
        const getViewCompanyMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.showCompanyApiCallId = getViewCompanyMsg.messageId;
        getViewCompanyMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        getViewCompanyMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.showCompanyApiEndPoint}`
        );
        getViewCompanyMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );
        runEngine.sendMessage(getViewCompanyMsg.id, getViewCompanyMsg);
    }

    navigateToCompanyDetail = () => {
        const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
        navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "CompanyDetails");
        navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigateMsg);
    }
    navigateToBack = () => {
        const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
        navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "Dashboard");
        navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigateMsg);
    }
    // Customizable Area End

}