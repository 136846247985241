import React from "react";
// Customizable Area Start
import {
    Box,
    Grid,
    styled,
    Typography,
    Button,
    CardContent,
    Card,
    Divider
} from "@mui/material";
import DashboardHeader from "../../dashboard/src/DashboardHeader.web";
import { backArrow, emptyDashboardImg, competenciesImg, companyImg, genderImg, birthDateImg, emailImg, contactImg, userNameImg, defaultUserImg } from "./assets";
// Customizable Area End

import ViewUserProfileController, {
    Props
} from "./ViewUserProfileController";

export default class ViewUserProfile extends ViewUserProfileController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    firstName = () => {
        return (
            <Wrapper>
                <Box className="userRightContainerBox">
                    <Box>
                        <img
                            src={userNameImg.default}
                            alt="User Profile"
                            className="userImageStyle"
                        />
                    </Box>
                    <Box className="nameMargin">
                        <Typography className="userDetailLabel">
                            First Name
                        </Typography>
                        <Typography className="userDetailSubText">
                            Cameron
                        </Typography>
                    </Box>
                </Box>
            </Wrapper >
        )
    }

    lastName = () => {
        return (
            <Wrapper>
                <Box className="userRightContainerBox">
                    <Box>
                        <img
                            src={userNameImg.default}
                            alt="User Profile"
                            className="userImageStyle"
                        />
                    </Box>
                    <Box className="nameMargin">
                        <Typography className="userDetailLabel">
                            Last Name
                        </Typography>
                        <Typography className="userDetailSubText">
                            Rain
                        </Typography>
                    </Box>
                </Box>
            </Wrapper>
        )
    }

    userEmail = () => {
        return (
            <Wrapper>
                <Box className="userRightContainerBox">
                    <Box>
                        <img
                            src={emailImg.default}
                            alt="User Profile"
                            className="userImageStyle"
                        />
                    </Box>
                    <Box className="nameMargin">
                        <Typography className="userDetailLabel">
                            Email
                        </Typography>
                        <Typography className="userDetailSubText">
                            you@gmail.com
                        </Typography>
                    </Box>
                </Box>
            </Wrapper>
        )
    }

    userContactNumber = () => {
        return (
            <Wrapper>
                <Box className="userRightContainerBox">
                    <Box>
                        <img
                            src={contactImg.default}
                            alt="User Profile"
                            className="userImageStyle"
                        />
                    </Box>
                    <Box className="nameMargin">
                        <Typography className="userDetailLabel">
                            Contact Number
                        </Typography>
                        <Typography className="userDetailSubText">
                            +91 95831 26000
                        </Typography>
                    </Box>
                </Box>
            </Wrapper>
        )
    }

    userBirthDate = () => {
        return (
            <Wrapper>
                <Box className="userRightContainerBox">
                    <Box>
                        <img
                            src={birthDateImg.default}
                            alt="User Profile"
                            className="userImageStyle"
                        />
                    </Box>
                    <Box className="nameMargin">
                        <Typography className="userDetailLabel">
                            Date of Birth
                        </Typography>
                        <Typography className="userDetailSubText">
                            03/09/1998
                        </Typography>
                    </Box>
                </Box>
            </Wrapper>
        )
    }

    userGender = () => {
        return (
            <Wrapper>
                <Box className="userRightContainerBox">
                    <Box>
                        <img
                            src={genderImg.default}
                            alt="User Profile"
                            className="userImageStyle"
                        />
                    </Box>
                    <Box className="nameMargin">
                        <Typography className="userDetailLabel">
                            Gender
                        </Typography>
                        <Typography className="userDetailSubText">
                            Male
                        </Typography>
                    </Box>
                </Box>
            </Wrapper>
        )
    }

    userDesignation = () => {
        return (
            <Wrapper>
                <Box className="userRightContainerBox">
                    <Box>
                        <img
                            src={userNameImg.default}
                            alt="User Profile"
                            className="userImageStyle"
                        />
                    </Box>
                    <Box className="nameMargin">
                        <Typography className="userDetailLabel">
                            Designation
                        </Typography>
                        <Typography className="userDetailSubText">
                            Android Developer
                        </Typography>
                    </Box>
                </Box>
            </Wrapper >
        )
    }

    userDepartment = () => {
        return (
            <Wrapper>
                <Box className="userRightContainerBox">
                    <Box>
                        <img
                            src={genderImg.default}
                            alt="User Profile"
                            className="userImageStyle"
                        />
                    </Box>
                    <Box className="nameMargin">
                        <Typography className="userDetailLabel">
                            Department
                        </Typography>
                        <Typography className="userDetailSubText">
                            Development
                        </Typography>
                    </Box>
                </Box>
            </Wrapper>
        )
    }

    userCompany = () => {
        return (
            <Wrapper>
                <Box className="userRightContainerBox">
                    <Box>
                        <img
                            src={companyImg.default}
                            alt="User Profile"
                            className="userImageStyle"
                        />
                    </Box>
                    <Box className="nameMargin">
                        <Typography className="userDetailLabel">
                            Company
                        </Typography>
                        <Typography className="userDetailSubText">
                            Infinix IT Sollutions
                        </Typography>
                    </Box>
                </Box>
            </Wrapper>
        )
    }

    userCompetencies = () => {
        return (
            <Wrapper>
                <Box className="userRightContainerBox">
                    <Box>
                        <img
                            src={competenciesImg.default}
                            alt="User Profile"
                            className="userImageStyle"
                        />
                    </Box>
                    <Box className="nameMargin">
                        <Typography className="userDetailLabel">
                            Competencies
                        </Typography>
                        <Typography className="userDetailSubText extraMargin">
                            Core Competencies
                        </Typography>
                        <Typography className="userDetailSubText extraMargin">
                            Behavioural Competencies
                        </Typography>
                        <Typography className="userDetailSubText extraMargin">
                            Leadership Competencies
                        </Typography>
                    </Box>
                </Box>
            </Wrapper>
        )
    }

    userImageAndName = () => {
        return (
            <Wrapper>
                <Grid container spacing={2}
                    sx={{
                        justifyContent: {
                            md: "space-between",
                            xs: "center"
                        }
                    }}>
                    <Grid item xs={12} sm={3.5} style={{ textAlign: "center" }}>
                        <img
                            src={defaultUserImg.default}
                            alt="Candidate Pic"
                            className="userPhoto" />
                    </Grid>
                    <Grid item xs={12} sm={7.5} className="userNameBox"
                        sx={{
                            alignItems: {
                                sm: "baseline",
                                xs: "center"
                            }
                        }}>
                        <Box>
                            <Typography className="candidateName">
                                Cameron Rain
                            </Typography>
                            <Typography className="staffID">
                                Staff ID : 10011
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Wrapper>
        )
    }

    noAssessment = () => {
        return (
            <EmptyWrapper>
                <Grid container>
                    <Grid item xs={12}>
                        <Box className="noDataImageBox">
                            <img src={emptyDashboardImg.default} />
                            <Typography className="noDataMainText">Ohh No, No Assessment Found</Typography>
                            <Typography className="noDataNextText">It looks like there’s nothing here yet.</Typography>
                        </Box>
                    </Grid>
                </Grid>
            </EmptyWrapper>
        )
    }

    viewUserProfilesHeader = () => {
        return (
            <Wrapper>
                <Box className="orgItemMainBox">
                    <Grid container justifyContent="center" className="orgCardContainer" >
                        <Grid item xs={12} className="companyCardItem">
                            <Card className="userProfileDescBox">
                                <CardContent className="cardContentPadding">
                                    <Grid container>
                                        <Grid item xs={12} className="orgDetailPadding">
                                            <Typography className="cardTitle">
                                                Candidate
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} className="dividerPadding">
                                            <Divider />
                                        </Grid>
                                        <Grid item xs={12} className="orgDetailPadding">
                                            <Grid container justifyContent={"space-between"}>
                                                <Grid item xs={12} lg={4.5} className="userDetailGrid">
                                                    {this.userImageAndName()}
                                                </Grid>

                                                <Grid item xs={12} lg={7.2} className="userDetailGrid">
                                                    <Grid container spacing={3.5}>
                                                        <Grid item xs={12}>
                                                            <Typography className="aboutUSerText">
                                                                About Candidate
                                                            </Typography>
                                                            <Divider />
                                                        </Grid>

                                                        <Grid item xs={12} md={6}>
                                                            {this.firstName()}
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                                            {this.lastName()}
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                                            {this.userEmail()}
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                                            {this.userContactNumber()}
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                                            {this.userBirthDate()}
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                                            {this.userGender()}
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                                            {this.userDesignation()}
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                                            {this.userDepartment()}
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                                            {this.userCompany()}
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                                            {this.userCompetencies()}
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {this.noAssessment()}
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Box>
            </Wrapper >
        )
    }

    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <DashboardHeader selectedMenu="All users" id={this.props.id} navigation={this.props.navigation}>
                <MainWrapper>
                    <Grid container justifyContent={"center"}>
                        <Grid item xs={11}>
                            <Grid container alignItems={"center"} spacing={1}>
                                <Grid item xs={1.5} sm={1} md={0.8} lg={0.5}>
                                    <img src={backArrow.default}
                                        alt="Back Arrow"
                                        className="backArrowImg"
                                        data-test-id="navigate_to_create_user"
                                        onClick={this.navigateToCreateUser}
                                    />
                                </Grid>
                                <Grid item xs={10.5} sm={5} md={5.2} lg={5.5}>
                                    <Typography className="userTitle">John Doe’s Profile</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={6}>
                                    <Grid container spacing={2} justifyContent={"ceneter"}>
                                        <Grid item xs={12} sm={6} md={6} lg={4.5} className="editMainBox">
                                            <Button className="deactivateButton" data-test-id="edit_company" onClick={this.navigateToCreateUser}>
                                                <Typography className="deactivateText">Deactivate User</Typography>
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6} lg={4.5} className="editMainBox">
                                            <Button className="editProfileButton" data-test-id="edit_company" onClick={this.navigateToCreateUser}>
                                                <Typography className="editProfileText">Edit Profile</Typography>
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>

                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} md={11.2} lg={11.5}>
                            {this.viewUserProfilesHeader()}
                        </Grid>
                    </Grid>
                </MainWrapper >
            </DashboardHeader >
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export const Wrapper = styled(Box)({
    width: "100%",
    "& .dividerPadding": {
        padding: "0px 56px 0px 35px"
    },
    "& .userRightContainerBox": {
        display: "flex"
    },
    "& .nameMargin": {
        marginLeft: "5px"
    },
    "& .userDetailLabel": {
        fontFamily: "Urbanist",
        fontSize: "15px",
        fontWeight: 600,
        lineHeight: "18px",
        color: "#0C2755",
        marginBottom: "8px"
    },
    "& .extraMargin": {
        marginBottom: "8px"
    },
    "& .userDetailSubText": {
        fontFamily: "Urbanist",
        fontSize: "13px",
        fontWeight: 400,
        lineHeight: "18px",
        color: "#0C2755B2"
    },
    "& .userNameBox": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    },
    "& .userImageStyle": {
        height: "20px",
        width: "20px",
        marginTop: "2px"
    },
    "& .userProfileDescBox": {
        margin: "60px 5vw",
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 14px 144px 0px",
        width: "65vw",
        "@media(max-width:900px)": {
            width: "80vw"
        },
    },
    "& .userPhoto": {
        height: "7vw",
        width: "7vw",
        border: "4px solid lightgray",
        borderRadius: "50%",
        maxWidth: "130px",
        maxHeight: "130px",
        minWidth: "80px",
        minHeight: "80px"
    },
    "& .candidateName": {
        fontFamily: "Urbanist",
        fontSize: "20px",
        fontWeight: 600,
        lineHeight: "20px",
        color: "#2C3E50",
        marginLeft: "10px"
    },
    "& .staffID": {
        fontFamily: "Urbanist",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "20px",
        color: "#5E5873",
        marginLeft: "10px"
    },
    "& .orgDetailPadding": {
        padding: "30px 56px 30px 35px"
    },
    "& .orgItemMainBox": {
        width: "100%",
        overflow: "hidden",
        display: "flex",
        justifyContent: "center",
    },
    "& .companyCardItem": {
        flex: "0 0 auto",
        scrollSnapAlign: "center",
    },
    "& .cardContentPadding": {
        padding: "0px 0px 42px 0px"
    },
    "& .contentPadding": {
        padding: "30px 56px 0px 35px"
    },
    "& .cardTitle": {
        fontFamily: "Urbanist",
        fontSize: "20px",
        fontWeight: 600,
        lineHeight: "24px",
        color: "#0444352"
    },
    "& .userDetailGrid": {
        border: "1px solid #E2E2E5",
        padding: "20px",
        margin: "10px 0px",
        borderRadius: "5px",
        height: "100%"
    },
    "& .aboutUSerText": {
        fontFamily: "Urbanist",
        fontSize: "18px",
        fontWeight: 600,
        lineHeight: "22px",
        paddingBottom: "20px",
        color: "#000104"
    },
    "& .orgCardContainer": {
        width: "100%",
        overflowX: "auto",
        scrollSnapType: "x mandatory",
        scrollBehavior: "smooth",
        padding: "0px 8px",
        scrollbarWidth: "none",
        "&::-webkit-scrollbar": {
            display: "none",
        },
    }
})
export const MainWrapper = styled(Box)({
    width: "100%",
    marginTop: "42px",
    paddingBottom: "30px",
    display: "flex",
    justifyContent: "center",
    "& .editMainBox": {
        display: "flex",
        justifyContent: "flex-end",
        "@media(max-width:600px)": {
            justifyContent: "flex-start",
            marginTop: "10px"
        }
    },
    "& .editProfileButton": {
        padding: "10px 25px",
        borderRadius: "4px",
        height: "58px",
        width: "100%",
        border: "1px solid #94A3B8",
        background: "#044352",
        "@media(max-width:599px)": {
            width: "60%"
        }
    },
    "& .deactivateButton": {
        padding: "10px 25px",
        borderRadius: "4px",
        height: "58px",
        width: "100%",
        border: "2px solid #94A3B8",
        "@media(max-width:599px)": {
            width: "60%"
        }
    },
    "& .deactivateText": {
        fontWeight: 700,
        lineHeight: "24px",
        fontFamily: "Urbanist",
        fontSize: "16px",
        color: "#044352",
        textTransform: "none"
    },
    "& .backArrowImg": {
        paddingRight: "12px"
    },
    "& .editProfileText": {
        fontWeight: 700,
        lineHeight: "24px",
        fontFamily: "Urbanist",
        fontSize: "16px",
        color: "#FFFFFF",
        textTransform: "none"
    },
    "& .userTitle": {
        fontSize: "25px",
        lineHeight: "30px",
        fontWeight: 600,
        fontFamily: "Urbanist",
        color: "#000104",
        marginLeft: "12px"
    },
})
export const EmptyWrapper = styled(Box)({
    width: "100%",
    "& .noDataNextText": {
        width: "40%",
        fontWeight: 400,
        color: "#8A96A8",
        fontFamily: "Urbanist",
        fontSize: "18px",
        lineHeight: "26px",
        textAlign: "center"
    },
    "& .addNewUserText": {
        fontWeight: 700,
        fontFamily: "Urbanist",
        fontSize: "16px",
        lineHeight: "24px",
        color: "#FFFFFF",
        textTransform: "none"
    },
    "& .noDataMainText": {
        color: "#000104",
        fontFamily: "Urbanist",
        fontWeight: 700,
        fontSize: "30px",
        lineHeight: "40px",
        padding: "0px 20px",
        textAlign: "center"
    },
    "& .noDataImageBox": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
})

// Customizable Area End