import React from "react";

// Customizable Area Start
import {     
  Box,
  Grid,
  styled,
  Typography,
  Card,
  CardContent,
  Divider,
  Select,
  InputBase,
  MenuItem,
  Button,
  Modal, 
  LinearProgress } from "@mui/material";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import  DashboardHeader from "../../dashboard/src/DashboardHeader.web";
import {arrow_Left, progress } from "./assets";
import CustomPopup from "../../../components/src/CustomSuccessModel.web";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { withTranslation } from "react-i18next";

// Customizable Area End

import Cfchatgptintegration9Controller, {
  Props,
  configJSON,
} from "./Cfchatgptintegration9Controller";

export default class Cfchatgptintegration9 extends Cfchatgptintegration9Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  jobDescValidationSchema = Yup.object({
    jobTitle: Yup.string().required("Required field"),
    DepartmentTeam: Yup.string().required("Required field"),
    jobLevel: Yup.string().required("Required field"),
    jdStyle: Yup.string().required("Required field"),
    jobFamily: Yup.string().required("Required field"),
    jobSummary: Yup.string().required("Required field"),  
    keySkills: Yup.string().required("Required field"),
    keyWords: Yup.string().required("Required field")
  });

  emptyDataScreen = () => {
    return (
      <MainWrapper>
        <Grid container justifyContent={"center"} marginTop={"50px"}>
          <Grid item xs={11}>
              <Grid container spacing={1} alignItems={"center"}>
                  <Grid item xs={12} sm={12} md={11.2} lg={11.5}>
                    <Wrapper>
                    <Box className="progressImage">
                      <img src={progress.default} />
                    </Box>
                <Typography className="emptyText">
                  Ohh No, No data found
                </Typography>
                <Typography className="emptyScreenMessage">
                  It looks like there's nothing here yet. Add New Job for greater
                </Typography>
                <Typography className="emptyScreenMessage">
                  {this.props.i18n?.t("efficiency")}
                </Typography>
                <Grid container justifyContent="center">
                        <Grid item>
                            <Button data-test-id="createNewJob" className="jobCreationbutton" onClick={this.createNewJob}>
                                <Typography className="jobCreationText">Create New Job</Typography>
                            </Button>
                        </Grid>
              </Grid>
        </Wrapper>
                  </Grid>
              </Grid>
              </Grid>
        </Grid>
      </MainWrapper>
    )
  }

  progressDataScreen = () => {
    return  (
      <Grid container justifyContent={"center"}>
                <Grid item xs={11}>
                    <Grid container spacing={1} alignItems={"center"}>
                        <Grid item xs={1.5} sm={1} md={0.8} lg={0.5}  >
                        <Box data-test-id="backToForm" onClick={this.backToForm}
                        style={{width:"47px" , height:"47px", border:"1px solid #ECECEC" , borderRadius:"50%",
                        display: "flex", alignItems: "center", justifyContent: "center"
                        }}
                          >
                            <img src={arrow_Left.default}
                                alt="Back Arrow"  
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={10.5} sm={11} md={11.2} lg={11.5}>
                            <Typography className="jobTitle">Generating Job Description</Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} className="jobMainHeaderGrid">
                        <Grid item xs={12} sm={12} md={11.2} lg={11.5}>
                          <Wrapper>
                <Box className="progressMainContainer">
                <Box className="progressContainer">
                <Box className="progressImage">
                  <img src={progress.default} />
                </Box>
                <Box className="dotsProgress">
                  {[0, 1, 2, 3, 4, 5, 6].map((obj, index) => <Box key={index} style={{width:"7.5px" , height:"7.5px" , 
                  borderRadius:"50%", backgroundColor:"#059669",
                  transform: `${this.handleCondition(index === this.state.active , "scale(1.5)" , "scale(1)")}`
                  }}></Box>)}
                </Box>
                <Box className="linearProgressBar">
                    <LinearProgress
                      className="linearProgress"
                      variant="determinate"
                      value={this.state.progress}
                    />
                  </Box>
                    <Box className="ProgressTextContain">
                      <Typography className="progressTextRunning">
                        {this.state.progress}% Complete, Finalising Job Description..
                      </Typography>
                    </Box>
                    <Box className="ProgressCreationContent">
                      <Typography className="progressContent1">
                        Hang tight! Job Description Creation in Progress...
                      </Typography>
                      <Typography className="progressContent2">
                        Please wait while we generate the Job Description based on your inputs.
                      </Typography>
                    </Box>
                </Box>
              </Box>
              </Wrapper>
                        </Grid>
                    </Grid>
                </Grid>
              </Grid>
    )
  }
  
  jobDetailDataScreen = () => {
    return (
      <Grid container justifyContent={"center"}>
      <Grid xs={11} >
          <Grid container spacing={1} alignItems={"center"}>
              <Grid item xs={1.5} sm={1} md={0.8} lg={0.5}  >
              <Box data-test-id="backToForm"
              style={{width:"47px" , height:"47px", border:"1px solid #ECECEC" , borderRadius:"50%",
              display: "flex", alignItems: "center", justifyContent: "center"
              }}
                >
                  <img src={arrow_Left.default}
                      alt="Back Arrow"  
                  />
                </Box>
              </Grid>
              <Grid item xs={10.5} sm={11} md={11.2} lg={11.5} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                  <Typography className="jobTitle">Job Description</Typography>
                  <Grid 
                  item 
                  xs={12} sm={12} md={5} 
                  display={"flex"} 
                  gap={3} 
                  justifyContent={{ xs: "center", md: "flex-end" }} 
                  sx={{
                    flexDirection: { xs: 'column', sm: 'row' },
                    alignItems: { xs: 'stretch', sm: 'center' },
                    mr: { sm: 0, md: '5rem' },
                  }}
                >
                <Select
                  sx={{
                    backgroundColor: "#044352",
                    width: { xs: '100%', sm: '100%' },
                    height: "58px",
                    padding: "10px 16px",
                    borderRadius: "4px",
                    "& .MuiSelect-icon": {
                      color: "#FFFFFF",
                      transform: 'none !important',
                    },
                  }}
                  data-test-id="setDownload"
                  className="downloadButton"
                  variant="outlined"
                  name="download"
                  displayEmpty
                  value={this.state.selectedOption}
                  onChange={this.handleChange}
                  IconComponent={KeyboardArrowRightIcon}
                  input={<InputBase />}
                  renderValue={() => (
                    <span style={{
                        color: '#FFFFFF',
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontFamily: "Urbanist",
                        fontSize: "16px",
                        fontWeight: 500,
                        lineHeight: "28px",
                      }}>
                        Download
                      </span>
                  )}
                >
                  {configJSON.downloadDropDownData.map((option: string, index: number) => (
                    <MenuItem key={index} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
                  <Button 
                    data-test-id="JobDetail"
                    className="editButton" 
                    variant="outlined" 
                    sx={{
                      backgroundColor: "#044352",
                      width: { xs: '100%', sm: '100%' },
                      height: "58px",
                      padding: "10px 16px",
                      borderRadius: "4px",
                      textTransform:"none",
                      "&:hover": {
                        backgroundColor: "#044352",
                      },
                      "& .MuiTypography-root": {
                        fontFamily: "Urbanist",
                        fontSize: "16px",
                        fontWeight: 500,
                        lineHeight: "28px",
                        color: "#FFFFFF",
                      }
                    }}
                    onClick={()=> this.setState({editJobDetailScreen:true , jobDetailScreen:false})}
                  >
                    <Typography className="editButtonText">Edit Job Details</Typography>
                  </Button>
                </Grid>
              </Grid>
                <Grid item xs={12} sm={12} md={11.2} lg={11.5} className="jobDetailHeaderGrid">
                  <Wrapper>
                <Box className="companyItemMainBox">
                  <Grid container className="companyCardContainer" justifyContent={"flex-start"}>
                    <Grid item xs={12} className="companyCardItem">
                      <Card className="jobdetailBox">
                        <CardContent 
                        className="cardContentPadding"><Grid 
                          container> <Grid item xs={12} className="orgDetailPadding">
                              <Typography className="JobDetailTitle">Network Engineer
                              </Typography></Grid>
                            <Grid item xs={12}><Divider
                              sx={{ color:"#E2E8F0",width: 'calc(100% - 35px)',marginRight: '35px',}} /></Grid></Grid>
                          <Grid container spacing={3.5} className="contentPadding"><Grid item xs={12} md={6}>
                              <Wrapper>
                                <Box className="errorStyleMainbox">
                                  <Typography className="JobDetailLabel">
                                    Job ID: 01234
                                  </Typography>
                                </Box>
                                <Typography className="JobDetailContent">
                                  Job Title: Network Engineer
                                </Typography>
                                <Typography className="JobDetailContent">
                                  Department/Team: Network Operation Centre
                                </Typography>
                                <Typography className="JobDetailContent">
                                  Job Family: 01
                                </Typography>
                              </Wrapper>
                            </Grid>
                          </Grid>
                          <Grid item xs={11.65} marginTop={"25px"}>
                            <Divider
                            sx={{ 
                              color:"#E2E8F0",
                              width: 'calc(100% - 35px)',
                              marginLeft: '35px',
                            }} 
                            />
                          </Grid>
                          <Grid container spacing={3.5} className="contentPadding">
                            <Grid item xs={10.9}>
                              <Wrapper>
                                <Box className="errorStyleMainbox">
                                  <Typography className="JobDetailLabel">
                                    Company 
                                  </Typography>
                                </Box>
                                <Typography className="JobDetailContent">
                                  The organization operates in an agile and collaborative culture. Our core values are collaboration, agility, simplicity, and fun. We believe
                                  in fostering a supportive and engaging work environment where every team member can thrive.
                                </Typography>
                              </Wrapper>
                            </Grid>
                          </Grid>
                          <Grid item xs={11.65} marginTop={"25px"}>
                            <Divider
                            sx={{ 
                              color:"#E2E8F0",
                              width: 'calc(100% - 35px)',
                              marginLeft: '35px',
                            }} 
                            />
                          </Grid>
                          <Grid container spacing={3.5} className="contentPadding">
                            <Grid item xs={11}>
                              <Wrapper>
                                <Box className="errorStyleMainbox">
                                  <Typography className="JobDetailLabel">
                                    Company Overview
                                  </Typography>
                                </Box>
                                <Typography className="JobDetailContent">
                                We are a mature telecom company with over 2000 employees. Our strategic focus is on digital transformation, ICT, and growth and
                                expansion into other regions. We are dedicated to providing innovative solutions and exceptional service to our clients.
                                </Typography>
                              </Wrapper>
                            </Grid>
                          </Grid>
                          <Grid item xs={11.65} marginTop={"25px"}>
                            <Divider
                            sx={{ 
                              color:"#E2E8F0",
                              width: 'calc(100% - 35px)',
                              marginLeft: '35px',
                            }} 
                            />
                          </Grid>
                          <Grid container spacing={3.5} className="contentPadding">
                            <Grid item xs={11}>
                              <Wrapper>
                                <Box className="errorStyleMainbox">
                                  <Typography className="JobDetailLabel">
                                    Job Responsibilities
                                  </Typography>
                                </Box>
                                <Typography className="JobDetailContent">
                                  As a Network Engineer in our Network Operation Centre, you will be responsible for:
                                </Typography>
                                <Typography className="JobDetailContent">1. Monitoring network performance and ensuring system availability and reliability.</Typography>
                                <Typography className="JobDetailContent">2. Configuring and installing various network devices and services (e.g., routers, switches, firewalls, load balancers, VPN, QoS).</Typography>
                                <Typography className="JobDetailContent">3. Performing network maintenance and system upgrades including service packs, patches, hot fixes, and security configurations.</Typography>
                                <Typography className="JobDetailContent">4. Monitoring system resource utilization, trending, and capacity planning.</Typography>
                                <Typography className="JobDetailContent">5. Providing Level-2/3 support and troubleshooting to resolve issues.</Typography>
                                <Typography className="JobDetailContent">6. Liaising with vendors and other IT personnel for problem resolution.</Typography>
                                <Typography className="JobDetailContent">7. Ensuring compliance with network security policies and procedures.</Typography>
                                <Typography className="JobDetailContent">8. Assisting in the design and implementation of new solutions and improving resilience of the current environment.</Typography>
                              </Wrapper>
                            </Grid>
                          </Grid>
                          <Grid item xs={11.65} marginTop={"25px"}>
                            <Divider
                            sx={{ 
                              color:"#E2E8F0",
                              width: 'calc(100% - 35px)',
                              marginLeft: '35px',
                            }} 
                            />
                          </Grid>
                          <Grid container spacing={3.5} className="contentPadding">
                            <Grid item xs={11}>
                              <Wrapper>
                                <Box className="errorStyleMainbox">
                                  <Typography className="JobDetailLabel">
                                    Job Requirements
                                  </Typography>
                                </Box>
                                <Typography className="JobDetailContent">
                                  To be successful in this role, you should have:
                                </Typography>
                                <Typography className="JobDetailContent">1. Proven hands-on network engineering experience.</Typography>
                                <Typography className="JobDetailContent">2. Professional certification (e.g., CCNP, CCDP).</Typography>
                                <Typography className="JobDetailContent">3. Solid understanding of networking protocols (e.g., IPSEC, HSRP, BGP, OSPF, 802.11, QoS).</Typography>
                                <Typography className="JobDetailContent">4. Hands-on experience with monitoring, network diagnostic, and network analytics tools.</Typography>
                                <Typography className="JobDetailContent">5. Ability to work independently and collaboratively in a fast-paced environment.</Typography>
                                <Typography className="JobDetailContent">6. Strong problem-solving and analytical skills.</Typography>
                                <Typography className="JobDetailContent">7. Excellent communication and interpersonal skills.</Typography>
                              </Wrapper>
                            </Grid>
                          </Grid>
                          <Grid item xs={11.65} marginTop={"25px"}>
                            <Divider
                            sx={{ 
                              color:"#E2E8F0",
                              width: 'calc(100% - 35px)',
                              marginLeft: '35px',
                            }} 
                            />
                          </Grid>
                          <Grid container spacing={3.5} className="contentPadding">
                            <Grid item xs={11} marginBottom={"60px"}>
                              <Wrapper>
                                <Box className="errorStyleMainbox">
                                  <Typography className="JobDetailLabel">
                                   Application Instructions
                                  </Typography>
                                </Box>
                                <Typography className="JobDetailContent">
                                 If you are passionate about network engineering and looking for a challenging and rewarding role in a dynamic telecom company, we encourage you to apply. Please submit your resume and cover letter to [email address] with the subject line 'Network Engineer Application'.
                                </Typography>
                              </Wrapper>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </Box>
                  </Wrapper>
                  </Grid>
          </Grid>
      </Grid>
    </Grid>
    )
  }

  editJobDetailDataScreen = () => {
    return (
      <Grid container justifyContent={"center"}>
      <Grid item xs={11}>
          <Grid container spacing={1} alignItems={"center"}>
              <Grid item xs={1.5} sm={1} md={0.8} lg={0.5}  >
              <Box data-test-id="editJobDetail"
              style={{width:"47px" , height:"47px", border:"1px solid #ECECEC" , borderRadius:"50%",
              display: "flex", alignItems: "center", justifyContent: "center"
              }}
              onClick={()=> this.setState({editJobDetailScreen:false , jobDetailScreen:true})}
                >
                  <img src={arrow_Left.default}
                      alt="Back Arrow"  
                  />
                </Box>
              </Grid>
              <Grid item xs={10.5} sm={11} md={11.2} lg={11.5} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                  <Typography className="jobTitle">Edit Job Description</Typography>
              </Grid>
                <Grid item xs={12} sm={12} md={11.2} lg={11.5} className="jobDetailHeaderGrid">
                  <Wrapper>
                <Box className="companyItemMainBox">
                  <Grid container className="companyCardContainer" justifyContent={"flex-start"}>
                    <Grid item xs={12} className="companyCardItem">
                      <Card className="companyCardDescBox">
                        <CardContent className="cardContentPadding">
                          <Grid container>
                            <Grid item xs={12} className="orgDetailPadding">
                              <Typography className="JobDetailTitle">
                                Network Engineer
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Divider
                              sx={{ 
                                color:"#E2E8F0",
                                width: 'calc(100% - 35px)',
                                marginRight: '35px',
                              }} 
                              />
                            </Grid>
                          </Grid>
                          <Grid container spacing={3.5} className="contentPadding">
                          <Grid item xs={12} md={6}>
                            <Wrapper>
                                <Box className="errorStyleMainbox">
                                    <Typography className="cardInputLabel">
                                      Job ID <span>*</span>
                                    </Typography>
                                </Box>
                                <input
                                  className="dropDownStyle"
                                  name="JobID"
                                  value="012345"
                                />
                            </Wrapper>
                            </Grid>
                            <Grid item xs={12} md={6}>
                            <Wrapper>
                                <Box className="errorStyleMainbox">
                                    <Typography className="cardInputLabel">
                                      Job Family <span>*</span>
                                    </Typography>
                                </Box>
                                <input
                                    className="dropDownStyle"
                                    name="Jobfamily"
                                    value="01"
                                  />
                            </Wrapper>
                            </Grid>
                            <Grid item xs={12} md={6}>
                            <Wrapper>
                                <Box className="errorStyleMainbox">
                                    <Typography className="cardInputLabel">
                                      Job Title <span>*</span>
                                    </Typography>
                                </Box>
                                <input
                                  className="dropDownStyle"
                                  name="JobTitle"
                                  value="IT Technician"
                                />
                            </Wrapper>
                            </Grid>
                            <Grid item xs={12} md={6}>
                            <Wrapper>
                                <Box className="errorStyleMainbox">
                                    <Typography className="cardInputLabel">
                                     Department/Team <span>*</span>
                                    </Typography>
                                </Box>
                                <input
                                    className="dropDownStyle"
                                    name="DepartmentTeam"
                                    value="IT specialist"
                                  />
                            </Wrapper>
                            </Grid>
                          </Grid>
                          <Grid container spacing={3.5} className="contentPadding">
                          <Grid item xs={12}>
                            <Wrapper>
                                <Box className="errorStyleMainbox">
                                    <Typography className="cardInputLabel">
                                     Company Culture <span>*</span>
                                    </Typography>
                                </Box>
                                <textarea
                                    className={`cultureInputField`}
                                    name="CompanyCulture"
                                    value="Donec a eros justo. Fusce egestas tristique ultrices. Nam tempor, augue nec tincidunt molestie, massa
                                     nunc varius arcu, at scelerisque elit erat a magna. Donec quis erat at libero ultrices mollis. In hac
                                    habitasse platea dictumst. Vivamus vehicula leo dui, at porta nisi facilisis finibus. In euismod augue vitae..."
                                />
                                <Typography className="companyCultureLimit">24/1000</Typography>
                            </Wrapper>
                            </Grid>
                            <Grid item xs={12}>
                            <Wrapper>
                                <Box className="errorStyleMainbox">
                                    <Typography className="cardInputLabel">
                                      Company Overview <span>*</span>
                                    </Typography>
                                </Box>
                                <textarea
                                    className={`cultureInputField`}
                                    name="CompanyOverview"
                                    value="Donec a eros justo. Fusce egestas tristique ultrices. Nam tempor, augue nec tincidunt molestie, massa
                                     nunc varius arcu, at scelerisque elit erat a magna. Donec quis erat at libero ultrices mollis. In hac
                                    habitasse platea dictumst. Vivamus vehicula leo dui, at porta nisi facilisis finibus. In euismod augue vitae..."
                                />
                                <Typography className="companyCultureLimit">24/1000</Typography>
                            </Wrapper>
                            </Grid>
                            <Grid item xs={12}>
                            <Wrapper>
                                <Box className="errorStyleMainbox">
                                    <Typography className="cardInputLabel">
                                      Job Responsibilities <span>*</span>
                                    </Typography>
                                </Box>
                                <textarea
                                    className={`cultureInputField`}
                                    name="JobResponsibilities"
                                    value="Donec a eros justo. Fusce egestas tristique ultrices. Nam tempor, augue nec tincidunt molestie, massa
                                     nunc varius arcu, at scelerisque elit erat a magna. Donec quis erat at libero ultrices mollis. In hac
                                    habitasse platea dictumst. Vivamus vehicula leo dui, at porta nisi facilisis finibus. In euismod augue vitae..."
                                />
                                <Typography className="companyCultureLimit">24/1000</Typography>
                            </Wrapper>
                            </Grid>
                            <Grid item xs={12}>
                            <Wrapper>
                                <Box className="errorStyleMainbox">
                                    <Typography className="cardInputLabel">
                                      Job Requirements <span>*</span>
                                    </Typography>
                                </Box>
                                <textarea
                                    className={`cultureInputField`}
                                    name="JobRequirements"
                                    value="Donec a eros justo. Fusce egestas tristique ultrices. Nam tempor, augue nec tincidunt molestie, massa
                                     nunc varius arcu, at scelerisque elit erat a magna. Donec quis erat at libero ultrices mollis. In hac
                                    habitasse platea dictumst. Vivamus vehicula leo dui, at porta nisi facilisis finibus. In euismod augue vitae..."
                                />
                                <Typography className="companyCultureLimit">24/1000</Typography>
                            </Wrapper>
                            </Grid>
                            <Grid item xs={12}>
                            <Wrapper>
                                <Box className="errorStyleMainbox">
                                    <Typography className="cardInputLabel">
                                      Application Instructions <span>*</span>
                                    </Typography>
                                </Box>
                                <textarea
                                    className={`cultureInputField`}
                                    name="ApplicationInstructions"
                                    value="Donec a eros justo. Fusce egestas tristique ultrices. Nam tempor, augue nec tincidunt molestie, massa
                                     nunc varius arcu, at scelerisque elit erat a magna. Donec quis erat at libero ultrices mollis. In hac
                                    habitasse platea dictumst. Vivamus vehicula leo dui, at porta nisi facilisis finibus. In euismod augue vitae..."
                                />
                                <Typography className="companyCultureLimit">24/1000</Typography>
                            </Wrapper>
                            </Grid>
                            <Grid item xs={12} style={{marginBottom:"60px"}}>
                              <Wrapper>
                                  <Grid container>
                                      <Grid item xs={12}>
                                          <Button className="sumbmitBtn" data-test-id="submit_company_details" type="submit">
                                              <Typography className="sumbmitText">Submit</Typography>
                                          </Button>
                                      </Grid>
                                  </Grid>
                              </Wrapper>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </Box>
                  </Wrapper>
                  </Grid>
          </Grid>
      </Grid>
    </Grid>
    )
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <DashboardHeader selectedMenu="Job descriptions" navigation={this.props.navigation} id={this.props.id}> 
      {this.handleTrueCondition(this.state.emptyscreen , this.emptyDataScreen())}               
        <Formik
            data-test-id="formik"
            initialValues={{
              jobTitle: '',
              DepartmentTeam: '',
              jobLevel: '',
              jdStyle: '',
              jobFamily:'',
              jobSummary: '',
              keySkills: [],
              keyWords: []
            }}
            validationSchema={this.jobDescValidationSchema}
            onSubmit={(values: any) => {
              this.jobDescriptionCall(values)
            }}
          >
            {({
              handleSubmit,
              values,
              handleChange,
              handleBlur,
              setFieldValue
            }) => (
              <Form
                onSubmit={handleSubmit}
                noValidate
                autoComplete="off"
              >
              <MainWrapper>
                {this.handleTrueCondition(this.state.formscreen
                ,
                <Grid container justifyContent={"center"}>
                <Grid item xs={11}>
                    <Grid container spacing={1} alignItems={"center"}>
                        <Grid item xs={1.5} sm={1} md={0.8} lg={0.5} >
                          <Box data-test-id="Dashboard" onClick={this.navigateToDashboard}
                          style={{width:"47px" , height:"47px", border:"1px solid #ECECEC" , borderRadius:"50%", display: "flex", alignItems: "center", justifyContent: "center"}}
                          >
                            <img src={arrow_Left.default}
                                alt="Back Arrow"  
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={10.5} sm={11} md={11.2} lg={11.5}>
                            <Typography className="jobTitle">Job Description</Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} className="jobMainHeaderGrid">
                        <Grid item xs={12} sm={12} md={11.2} lg={11.5}>
                          <Wrapper>
                              <Box className="companyItemMainBox">
                                  <Grid container className="companyCardContainer" justifyContent={"flex-start"}>
                                      <Grid item xs={12} className="companyCardItem">
                                          <Card className="companyCardDescBox">
                                              <CardContent className="cardContentPadding">
                                                  <Grid container>
                                                      <Grid item xs={12} className="orgDetailPadding">
                                                          <Typography className="cardTitle">
                                                            Job Description
                                                          </Typography>
                                                      </Grid>
                                                      <Grid item xs={12}>
                                                          <Divider />
                                                      </Grid>
                                                  </Grid>
                                                  <Grid container spacing={3.5} className="contentPadding">
                                                      <Grid item xs={12}>
                                                      <Wrapper>
                                                          <Box className="errorStyleMainbox">
                                                              <Typography className="cardInputLabel">
                                                                Job Title <span>*</span>
                                                              </Typography>
                                                              <ErrorMessage name="jobTitle" component="div" className="errorStyle" />
                                                          </Box>
                                                          <input
                                                            className="dropDownStyle"
                                                            placeholder="Specify your title"
                                                            name="jobTitle"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.jobTitle}
                                                          />
                                                      </Wrapper>
                                                      </Grid>
                                                      <Grid item xs={12} md={6}>
                                                      <Wrapper>
                                                          <Box className="errorStyleMainbox">
                                                              <Typography className="cardInputLabel">
                                                                Department/Team  <span>*</span>
                                                              </Typography>
                                                              <ErrorMessage name="DepartmentTeam" component="div" className="errorStyle" />
                                                          </Box>
                                                          <input
                                                              className="dropDownStyle"
                                                              placeholder="Specify your department/team"
                                                              name="DepartmentTeam"
                                                              onChange={handleChange}
                                                              onBlur={handleBlur}
                                                              value={values.DepartmentTeam}
                                                            />
                                                      </Wrapper>
                                                      </Grid>
                                                      <Grid item xs={12} md={6}>
                                                      <Wrapper>
                                                        <Box className="errorStyleMainbox">
                                                            <Typography className="cardInputLabel">
                                                            Job Level  <span>*</span>
                                                            </Typography>
                                                            <ErrorMessage name="jobLevel" component="div" className="errorStyle" />
                                                        </Box>
                                                        <Select
                                                        data-test-id="setJobLevel"
                                                        className={`dropDownStyle`}
                                                        inputProps={{
                                                            icon: {
                                                                left: 0,
                                                            },
                                                        }}
                                                        variant="outlined"
                                                        name="jobLevel"
                                                        value={values.jobLevel}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        displayEmpty
                                                        renderValue={()=> this.handleCondition(values.jobLevel
                                                          , values.jobLevel
                                                          ,<div className="dropDownStyleplaceHolder">
                                                                  Specify your title
                                                              </div>
                                                          )}
                                                        IconComponent={KeyboardArrowRightIcon}
                                                        input={<InputBase />}
                                                    >
                                                        {this.state.jobLevel?.map((option) => (
                                                            <MenuItem key={option.id} value={option.name}>
                                                                {option.name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                    </Wrapper>
                                                      </Grid>
                                                      <Grid item xs={12} md={6}>
                                                      <Wrapper>
                                                        <Box className="errorStyleMainbox">
                                                            <Typography className="cardInputLabel">
                                                            Job Experience <span>*</span>
                                                            </Typography>
                                                            <ErrorMessage name="jdStyle" component="div" className="errorStyle" />
                                                        </Box>
                                                        <Select
                                                        data-test-id="setJdStyle"
                                                        className={`dropDownStyle`}
                                                        inputProps={{
                                                            icon: {
                                                                left: 0,
                                                            },
                                                        }}
                                                        variant="outlined"
                                                        name="jdStyle"
                                                        value={values.jdStyle}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        displayEmpty
                                                        renderValue={
                                                          ()=> this.handleCondition(values.jdStyle
                                                            , values.jdStyle
                                                            ,
                                                                <div className="dropDownStyleplaceHolder">
                                                                    Select your job experience
                                                                </div>
                                                            )
                                                        }
                                                        IconComponent={KeyboardArrowRightIcon}
                                                        input={<InputBase />}
                                                    >
                                                        {this.state.jdstyle?.map((option) => (
                                                            <MenuItem key={option.id} value={option.name}>
                                                                {option.name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                    </Wrapper>
                                                      </Grid>
                                                      <Grid item xs={12} md={6}>
                                                      <Wrapper>
                                                        <Box className="errorStyleMainbox">
                                                            <Typography className="cardInputLabel">
                                                             Job Family <span>*</span>
                                                            </Typography>
                                                            <ErrorMessage name="jobFamily" component="div" className="errorStyle" />
                                                        </Box>
                                                        <Select
                                                        data-test-id="setJobFamily"
                                                        className={`dropDownStyle`}
                                                        inputProps={{
                                                            icon: {
                                                                left: 0,
                                                            },
                                                        }}
                                                        variant="outlined"
                                                        name="jobFamily"
                                                        value={values.jobFamily}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        displayEmpty
                                                        renderValue={()=> this.handleCondition(values.jobFamily
                                                          , values.jobFamily
                                                          ,<div className="dropDownStyleplaceHolder">
                                                                  Specify your job family
                                                              </div>
                                                          )}
                                                        IconComponent={KeyboardArrowRightIcon}
                                                        input={<InputBase />}
                                                    >
                                                        {this.state.jobFamily?.map((option) => (
                                                            <MenuItem key={option.id} value={option.name}>
                                                                {option.name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                    </Wrapper>
                                                      </Grid>
                                                      <Grid item xs={12}>
                                                      <Wrapper>
                                                          <Box className="errorStyleMainbox">
                                                              <Typography className="cardInputLabel">
                                                              Job Summary <span>*</span>
                                                              </Typography>
                                                              <ErrorMessage name="jobSummary" component="div" className="errorStyle" />
                                                          </Box>
                                                          <textarea
                                                              className={`cultureInputField`}
                                                              placeholder="Write about your job..."
                                                              name="jobSummary"
                                                              value={values.jobSummary}
                                                              onChange={handleChange}
                                                              onBlur={handleBlur}
                                                          />
                                                          <Typography className="companyCultureLimit">{this.state.jobSummary.length}/1000</Typography>
                                                      </Wrapper>
                                                      </Grid>
                                                      <Grid item xs={12} md={6}>
                                                      <Wrapper>
                                                        <Box className="errorStyleMainbox">
                                                            <Typography className="cardInputLabel">
                                                            Key Skills  <span>*</span>
                                                            </Typography>
                                                            <ErrorMessage name="keySkills" component="div" className="errorStyle" />
                                                        </Box>
                                                        <Select
                                                          data-test-id="setkeySkills"
                                                          className="dropDownStyle"
                                                          inputProps={{
                                                          icon: {
                                                            left: 0,
                                                          },
                                                          }}
                                                          variant="outlined"
                                                          name="keySkills"
                                                          value={values.keySkills}
                                                          onChange={(event) => {
                                                          const value = event.target.value;
                                                          setFieldValue("keySkills", typeof value === 'string' ? value.split(',') : value);
                                                          }}
                                                          onBlur={handleBlur}
                                                          multiple
                                                          displayEmpty
                                                          renderValue={(selected) => 
                                                            this.handleCondition(selected.length > 0 , selected.map((id:any) => {
                                                              const skill = this.state.keySkills.find(skill => skill.id === id);
                                                              return skill ? skill.name : "";
                                                            }).join(" | ") , <div className="dropDownStyleplaceHolder">Select your key skills</div>)
                                                          }
                                                          IconComponent={KeyboardArrowRightIcon}
                                                          input={<InputBase />}
                                                          >
                                                          {this.state.keySkills?.map((option: any) => (
                                                          <MenuItem key={option.id} value={option.id}>
                                                            {option.name}
                                                          </MenuItem>
                                                          ))}
                                                          </Select>
                                                    </Wrapper>
                                                      </Grid>
                                                      <Grid item xs={12} md={6}>
                                                      <Wrapper>
                                                        <Box className="errorStyleMainbox">
                                                            <Typography className="cardInputLabel">
                                                            Key Words  <span>*</span>
                                                            </Typography>
                                                            <ErrorMessage name="keyWords" component="div" className="errorStyle" />
                                                        </Box>
                                                        <Select
                                                            data-test-id="setkeyWords"
                                                            className="dropDownStyle"
                                                            name="keyWords"
                                                            variant="outlined"
                                                            value={values.keyWords}
                                                            onChange={(event) => {
                                                              const value = event.target.value;
                                                              setFieldValue("keyWords", typeof value === 'string' ? value.split(',') : value);
                                                            }}
                                                            onBlur={handleBlur}
                                                            displayEmpty
                                                            multiple
                                                            renderValue={(selected) => {
                                                              if (selected?.length > 0) {
                                                                return selected.map((obj:any) => {
                                                                    const wordObj = this.state.keyWords.find((words) => words.keywords === obj);
                                                                    console.log("wordObj",wordObj)
                                                                    return wordObj ? wordObj.keywords : "";
                                                                  })
                                                                  .join(" | ");
                                                              } else {
                                                                return (
                                                                  <div className="dropDownStyleplaceHolder">
                                                                    Select your key words
                                                                  </div>
                                                                );
                                                              }
                                                            }}
                                                            input={<InputBase />}
                                                            IconComponent={KeyboardArrowRightIcon}
                                                          >
                                                            {this.state.keyWords?.map((option, index) => (
                                                              <MenuItem key={index} value={option.keywords}>
                                                                {option.keywords}
                                                              </MenuItem>
                                                            ))}
                                                          </Select>
                                                    </Wrapper>
                                                      </Grid>
                                                      <Grid item xs={12}>
                                                        <Wrapper>
                                                            <Grid container>
                                                                <Grid item xs={12}>
                                                                    <Button className="sumbmitBtn" data-test-id="submit_company_details" type="submit">
                                                                        <Typography className="sumbmitText">Generate Job Description</Typography>
                                                                    </Button>
                                                                </Grid>
                                                            </Grid>
                                                        </Wrapper>
                                                      </Grid>
                                                  </Grid>
                                              </CardContent>
                                          </Card>
                                      </Grid>
                                  </Grid>
                              </Box>
                          </Wrapper>
                        </Grid>
                    </Grid>
                </Grid>
                </Grid>
                )}

                {
                  this.handleTrueCondition(
                    this.state.progressScreen ,
                  this.progressDataScreen()
                  )
                }
                {
                  this.handleTrueCondition(
                    this.state.jobDetailScreen ,
                  this.jobDetailDataScreen()
                  )
                }
                {
                  this.handleTrueCondition(
                    this.state.editJobDetailScreen,
                  this.editJobDetailDataScreen()
                  )
                }
            </MainWrapper>
          </Form>
            )}
          </Formik>
      <Modal      
          open={this.state.progressOpen}
          onClose={this.closeProgressModal}>
          <CustomPopup 
          data-test-id="custom-popup1"
          headingPopup={configJSON.ProgressheadingPopup} 
          titlePopup={this.state.jobdescResponse} 
          subTitlePopup = {configJSON.subTitleProgressPopup}
          buttonText={configJSON.buttonTextProgress}
          onOpen={this.openProgressModal}
          onClose={this.closeProgressModal}/>
      </Modal>
      </DashboardHeader>
      // Customizable Area End
    );
  }
}

export const Cfchatgptintegration9Web = withTranslation()(Cfchatgptintegration9)

export const MainWrapper = styled(Box)({
  display: "flex",
  justifyContent: "center",
  width: "100%",
  marginTop: "42px",
  paddingBottom: "30px",
  "& .jobTitle": {
      fontFamily: "Urbanist",
      fontSize: "25px",
      fontWeight: 600,
      lineHeight: "30px",
      color: "#000104",
      marginLeft: "12px"
  },
  "& .jobMainHeaderGrid": {
      marginTop: "30px"
  },
  "& .jobDetailHeaderGrid": {
    marginTop: "45px"
},
});

export const Wrapper = styled(Box)({
  width: "100%",
  "& .companyCardDescBox": {
      boxShadow: "rgba(0, 0, 0, 0.1) 0px 14px 144px 0px",
      width: "55vw",
      margin: "4vh 3vw",
      "@media(max-width:900px)": {
          width: "80vw"
      },
  },
  "& .jobdetailBox":{
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 14px 144px 0px",
      width: "62.5vw",
      margin: "4vh 3vw",
      "@media(max-width:900px)": {
          width: "80vw"
      },
  },
  "& .companyItemMainBox": {
      width: "100%",
      overflow: "hidden",
      display: "flex",
      justifyContent: "center",
  },
  "& .companyCardItem": {
      flex: "0 0 auto",
      scrollSnapAlign: "center",
  },
  "& .cardContentPadding": {
      padding: "0px 0px 42px 0px"
  },
  "& .orgDetailPadding": {
      padding: "30px 56px 30px 35px"
  },
  "& .contentPadding": {
      padding: "30px 56px 0px 35px"
  },
  "& .cardTitle": {
      fontFamily: "Urbanist",
      fontSize: "20px",
      fontWeight: 600,
      lineHeight: "24px",
      color: "#0444352"
  },
  "& .JobDetailTitle": {
    fontFamily: "Urbanist",
    fontSize: "20px",
    fontWeight: 600,
    lineHeight: "24px",
    color: "#044352"
},
  "& .cardInputLabel": {
      fontFamily: "Urbanist",
      fontSize: "14px",
      fontWeight: 700,
      lineheight: "22px",
      color: "#344054",
      marginBottom: "10px"
  },
  "& .JobDetailLabel": {
    fontFamily: "Urbanist",
    fontSize: "20px",
    fontWeight: 700,
    lineheight: "28px",
    color: "#0F172A",
    marginBottom: "10px"
},
  "& .JobDetailContent":{
    fontFamily: "Urbanist",
    fontSize: "16px",
    fontWeight: 400,
    lineheight: "22px",
    color: "#0F172A",
    marginBottom: "10px"
  },
  "& .errorStyle": {
      color: "#F87171",
      fontFamily: "Urbanist",
      fontSize: "16px",
      fontWeight: 400,
      fontStyle: "italic"
  },
  "& .errorStyleMainbox": {
      display: "flex",
      justifyContent: "space-between"
  },
  "& .companySuccessDialogClose": {
      top: 16,
      position: 'absolute',
      color: "#334155"
  },
  "& .viewDetailButtonsBox": {
      margin: "16px 0px",
      paddingRight: "16px",
      display: "flex",
      justifyContent: "flex-end"
  },
  "& .viewDetailButton": {
      height: "56px",
      fontFamily: "Urbanist",
      fontSize: "16px",
      fontWeight: 700,
      width: "30%",
      padding: "16px 24px",
      gap: "8px",
      borderRadius: "8px",
      opacity: "0px",
      marginLeft: "16px",
      background: "#044352",
      textTransform: "none",
      "&:hover": {
          backgroundColor: "#044352"
      },
  },
  "& .viewDetailButtonArabic": {
      gap: "8px",
      borderRadius: "8px",
      opacity: "0px",
      fontSize: "16px",
      fontWeight: 700,
      width: "120px",
      height: "56px",
      fontFamily: "Urbanist",
      padding: "16px 39.5px 16px 36.5px",
      marginRight: "16px",
      background: "#044352",
      textTransform: "none",
      "&:hover": {
          backgroundColor: "#044352"
      },
  },
  "& .viewDetailButtonsBoxArabic": {
      display: "flex",
      justifyContent: "flex-end",
      paddingLeft: "16px",
      margin: "16px 0px"
  },
  "& .companySuccessImageBox": {
      padding: "10px 0px",
      display: "flex",
      justifyContent: "center"
  },
  "& .companySuccessDialogTitle": {
      fontFamily: "Urbanist",
      fontWeight: 700,
      fontSize: "24px",
      lineHeight: "32px",
      margin: "8px 16px",
      color: "#1E1E1E"
  },
  "& .companyCardContainer": {
      display: "flex",
      justifyContent: "flex-start",
      width: "100%",
      overflowX: "auto",
      scrollSnapType: "x mandatory",
      scrollBehavior: "smooth",
      padding: "0px 8px",
      scrollbarWidth: "none",
      "&::-webkit-scrollbar": {
          display: "none",
      },
  },
  "& .chipContainer": {
      borderTop: '1px solid #CBD5E1',
      padding: "12px 16px",
  },
  "& .chip": {
      border: '1px solid #CBD5E1',
      margin: "12px 12px 12px 0px",
      borderRadius: "8px",
      fontFamily: "Urbanist",
      fontSize: "14px",
      fontWeight: 500,
      color: "#475361"
  },
  "& .organizationKeywords": {
      boxShadow: "0px 1px 2px 0px #1018280D",
      fontSie: "16px",
      fontFamily: 'Urbanist',
      border: '1px solid #CBD5E1',
      fontWeight: 400,
      lineHight: "24px",
      borderRadius: "8px",
  },
  "& .orgKeywordInput": {
      border: 0, padding: "24px 12px 24px"
  },
  "& .jdMainBox": {
      display: "flex",
      border: "1px solid #CBD5E1",
      borderRadius: "8px",
      alignItems: "center",
      padding: "20px 5px"
  },
  "& .jdInnerGrid": {
      display: "flex",
      justifyContent: "center"
  },
  "& .deleteJdImg": {
      display: "flex",
      justifyContent: "center"
  },
  "& .jdUploadText": {
      fontFamily: "Urbanist",
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "24px",
      color: "#0F172A"
  },
  "& .jdUploadSubText": {
      fontFamily: "Urbanist",
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "24px",
      color: "#64748B"

  },
  "& .errorClass": {
      border: '1px solid #F87171 !important',
  },
  "& .cultureInputField": {
      boxShadow: "0px 1px 2px 0px #1018280D",
      fontSie: "16px",
      fontFamily: 'Urbanist',
      border: '1px solid #CBD5E1',
      fontWeight: 400,
      lineHight: "24px",
      borderRadius: "8px",
      height: "98px",
      padding: "12px 16px",
      width: '100%',
      color: "#475569",
      "&:focus": {
          borderColor: "#CBD5E1",
          outline: "none",
      }
  },
  "& .companyCultureLimit": {
      display: "flex",
      justifyContent: "flex-end",
      fontSize: "12px",
      fontWeight: 400,
      fontFamily: "Urbanist",
  },
  "& .dropDownStyle": {
      backgroundColor: 'white',
      height: '66px',
      borderRadius: '8px',
      border: '1px solid #CBD5E1',
      fontSie: '16px',
      fontWeight: 700,
      lineHight: "24px",
      fontFamily: 'Urbanist',
      padding: '10px 12px',
      width: '100%',
      color: "#475569"
  },
  "& .dropDownStyleplaceHolder": {
      color: "#DCDCDC", 
      fontSize: 16, 
      fontWeight: 400
  } as React.CSSProperties,
    '& .MuiSelect-iconOpen': {
      transform: 'none',
    },
  "& .sumbmitBtn": {
      height: "65px",
      padding: "10px 16px 10px 16px",
      borderRadius: "4px 0px 0px 0px",
      background: "#044352",
      width: "100%"
  },
  "& .sumbmitText": {
      fontFamily: "Urbanist",
      fontFize: "16px",
      fontWeight: 700,
      lineHeight: "24px",
      textTransform: "none",
      color: "#FFFFFF"

  },
  "& input::placeholder, textarea::placeholder": {
      color: "#cccccc"
  },
  "& .progressMainContainer": {
    paddingLeft: "8rem",
    paddingBottom: "80px",
    "@media (max-width: 768px)": {
      paddingLeft: "6rem",
      paddingBottom: "40px",
    },
    "@media (max-width: 480px)": {
      paddingLeft: "5rem",
      paddingBottom: "20px",
    },
  },
  "& .progressContainer": {
    width: "1188px",
    height: "646px",
    boxShadow: "0px 14px 144px 0px #0000001A",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    "@media(max-width:1650px)": {
      width: "950px"
    },
    "@media(max-width:1350px)": {
      width: "650px"
    },
    "@media(max-width:970px)": {
      width: "550px"
    },
    "@media(max-width:720px)": {
      width: "450px"
    },
    "@media (max-width: 550px)": {
      width: "350px",
    },
    "@media (max-width: 450px)": {
      width: "250px",
    },
    "@media (max-width: 380px)": {
      width: "200px",
    },
  },
  "& .progressImage": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: "100%", 
    height: "auto", 
    "@media (max-width: 768px)": {
      paddingBottom: "20px",
    },
  },
  "& .dotsProgress": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: "30px",
    gap: "7.5px",
    "@media (max-width: 768px)": {
      gap: "5px",
    },
    "@media (max-width: 480px)": {
      gap: "3px",
    },
  },
  "& .linearProgressBar": {
    width: "359px",
    paddingTop: "30px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    "@media (max-width: 768px)": {
      width: "80%",
    },
    "@media (max-width: 480px)": {
      width: "100%",
    },
  },
  "& .linearProgress": {
    height: "10px",
    width: "100%",
    backgroundColor: "#E2E8F0",
    borderRadius: "5px",
    "& .MuiLinearProgress-bar": {
      backgroundColor: "#059669",
    },
  },
  "& .progressText": {
    color: "#059669",
    fontFamily: "Urbanist",
    fontSize: "2.1rem",
    lineHeight: "3.8rem",
    fontWeight: 700,
    width: "100%",
    textAlign: "center",
    "@media (max-width: 768px)": {
      fontSize: "1.5rem",
    },
    "@media (max-width: 480px)": {
      fontSize: "1rem",
    },
  },
  "& .progressTextRunning": {
    color: "#059669",
    fontFamily: "Urbanist",
    fontSize: "1.25rem",
    lineHeight: "1.75rem",
    fontWeight: 700,
    width: "100%",
    textAlign: "center",
    "@media (max-width: 768px)": {
      fontSize: "1rem",
    },
    "@media (max-width: 480px)": {
      fontSize: "0.875rem",
    },
  },
  "& .ProgressCreationContent": {
    textAlign: "center",
    paddingTop: "62px",
    "@media (max-width: 768px)": {
      paddingTop: "40px",
    },
    "@media (max-width: 480px)": {
      paddingTop: "20px",
    },
  },
  "& .ProgressContent1": {
    color: "#000104",
    fontFamily: "Urbanist",
    fontSize: "1.125rem",
    lineHeight: "1.5rem",
    fontWeight: 700,
    width: "100%",
    textAlign: "center",
    "@media (max-width: 768px)": {
      fontSize: "1rem",
    },
    "@media (max-width: 620px)": {
      fontSize: "0.875rem",
    },
  },
  "& .ProgressContent2": {
    color: "#000104",
    fontFamily: "Urbanist",
    fontSize: "1rem",
    lineHeight: "1.5rem",
    fontWeight: 400,
    width: "100%",
    textAlign: "center",
    "@media (max-width: 768px)": {
      fontSize: "0.875rem",
    },
    "@media (max-width: 620px)": {
      fontSize: "0.75rem",
    },
  },
  "& .emptyScreenMessage": {
    color: "#8A96A8",
    fontFamily: "Urbanist",
    fontSize: "1rem",
    lineHeight: "1.5rem",
    fontWeight: 400,
    width: "100%",
    textAlign: "center",
    "@media (max-width: 768px)": {
      fontSize: "0.875rem",
    },
    "@media (max-width: 620px)": {
      fontSize: "0.75rem",
    },
  },
  "& .jobCreationbutton": {
        top:"32px",
        padding: "10px 40px",
        width:"218px",
      borderRadius: "4px",
      background: "#044352",
  },
  "& .jobCreationText":{
    color: "#FFFFFF",
    fontFamily: "Urbanist",
    fontSize: "16px",
    fontWeight: "500",
    lineHeight:"28px",
    textTransform:"none",
  },
  "& .emptyText": {
    color: "#000104",
    fontFamily: "Urbanist",
    fontSize: "30px",
    lineHeight: "40px",
    fontWeight: 700,
    width: "100%",
    textAlign: "center",
  }
})

// Customizable Area Start