import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { SelectChangeEvent } from '@mui/material/Select';
import { getStorageData } from "framework/src/Utilities";
interface PostContactUs {
  message?: string,
  error?: string;
  contact?: {
    data?: {
      id: string,
      type: string,
      attributes: {
        first_name: string,
        last_name: string,
        email: string,
        country_code: string,
        mobile_number: string,
        message: string,
        privacy_policy_agreement: boolean,
      }
    }
  }
}
interface GetContactUS {
  success?: boolean,
  error?: string;
  data?: {
    contact_us_title: string,
    contact_us_headline: string,
  }
}
interface ContryCodeResponse {
  message?: string;
  error?: string;
  country_codes?: string[];
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  handleClickOpenPrivacy: () => void;
  scrollToSection?: (ref: React.RefObject<HTMLElement>) => void;
  mainHeaderRef?: React.RefObject<HTMLElement>;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  name: string;
  email: string;
  phoneNumber: string;
  comments: string;
  enableField: boolean;
  token: string;
  contactUsList: any;
  activeId: number;
  activeName: string;
  activeEmail: string;
  activePhoneNumber: string;
  activeDescription: string;
  activeCreatedAt: string;
  isVisible: boolean;
  firstName: string;
  lastName: string;
  userEmail: string;
  message: string;
  privacyCheck: boolean;
  sendMessageClicked: boolean;
  errorMsg: string;
  emailError: string;
  userPhoneNumber: string;
  countryCodeData: ContryCodeResponse;
  countryCode: string;
  messageError: string;
  firstNameError: string;
  lastNameError: string;
  phoneNumberError: string;
  postContactUs: PostContactUs;
  getContactUs: GetContactUS;
  selectedLanguage: string;
  openSuccess: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ContactusController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  contactUsApiCallId: any;
  deleteContactApiCallId: any;
  addContactApiCallId: any;
  postContactUsApiCallId: string = "";
  getCountryCodeApiCallId: string = "";
  getContactUsApiCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.contactUsApiCallId = "";
    this.deleteContactApiCallId = "";
    this.addContactApiCallId = "";

    this.state = {
      name: "",
      email: "",
      phoneNumber: "",
      comments: "",
      enableField: false,
      token: "",
      contactUsList: [],
      activeId: 0,
      activeName: "",
      activeEmail: "",
      activePhoneNumber: "",
      activeDescription: "",
      activeCreatedAt: "",
      isVisible: false,
      firstName: "",
      lastName: "",
      userEmail: "",
      message: "",
      privacyCheck: false,
      sendMessageClicked: false,
      errorMsg: "",
      emailError: "",
      userPhoneNumber: "",
      countryCodeData: {},
      countryCode: "91",
      messageError: "",
      firstNameError: "",
      lastNameError: "",
      phoneNumberError: "",
      postContactUs: {},
      selectedLanguage: "",
      getContactUs: {},
      openSuccess: false
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    // Customizable Area Start
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    this.getCountryCodeApiCall();
    const language = await getStorageData("language") || "English";
    this.setState({ selectedLanguage: language }, () => {
      this.getContactUsApiCall();
    });
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      runEngine.debugLog("TOKEN", token);
      this.setState({ token: token });
      this.getContactUsList(token);
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      runEngine.debugLog("API Message Recived", message);

      if (apiRequestCallId === this.postContactUsApiCallId) {
        this.postContactUsApiResponse(responseJson)
      }
      if (apiRequestCallId === this.getCountryCodeApiCallId) {
        this.countryCodeApiResponse(responseJson)
      }
      if (apiRequestCallId === this.getContactUsApiCallId) {
        this.getContactUsApiResponse(responseJson)
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  firstNameChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    this.setState({ firstName: event.target.value }, () => this.validationFirstName())
  }
  lastNameChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    this.setState({ lastName: event.target.value }, () => this.validationLastName())
  }
  userEmailChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    this.setState({ userEmail: event.target.value })
  }

  messageChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    this.setState({ message: event.target.value }, () => this.validationMessage())
  }

  privacyCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ privacyCheck: event.target.checked });
  }
  handleCountryCodeChange = (event: SelectChangeEvent<string>) => {
    this.setState({ countryCode: event.target.value });
  };

  handlePhoneNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const userPhoneNumber = event.target.value;

    const isNumeric = /^\d*$/.test(userPhoneNumber);

    if (isNumeric) {
      if (userPhoneNumber.length >= 6 && userPhoneNumber.length <= 10) {
        this.setState({ userPhoneNumber, phoneNumberError: "" });
      } else {
        const phoneNumberErrorText = this.state.selectedLanguage.toLowerCase() === "english" ?
          "Phone number must be between 6 and 10 digits." :
          "يجب أن يتراوح رقم الهاتف بين 6 و 10 أرقام.";

        this.setState({ userPhoneNumber, phoneNumberError: phoneNumberErrorText });
      }
    } else {
        const phoneNumberErrorText = this.state.selectedLanguage.toLowerCase() === "english" ?
        configJSON.phoneNumberErrorMsg :
        configJSON.phoneNumberErrorMsgArabic;

      this.setState({ userPhoneNumber, phoneNumberError: phoneNumberErrorText });
    }
  };


  handleCloseSuccess = () => {
    this.setState({ openSuccess: false }, () => {
    });
  };

  handleSuccessBrowsing = () => {
    this.setState({ openSuccess: false }, () => {
      if (this.props.scrollToSection && this.props.mainHeaderRef) {
        this.props?.scrollToSection(this.props.mainHeaderRef)
      }
    });
  };

  postContactUsApiResponse = (responseJson: PostContactUs) => {
    if (responseJson && !responseJson.error) {
      this.setState({
        postContactUs: responseJson,
        firstName: "",
        lastName: "",
        userEmail: "",
        message: "",
        userPhoneNumber: "",
        privacyCheck: false,
        openSuccess: true,
        sendMessageClicked: false,
        countryCode: "91"
      });
      setTimeout(() => {
        this.setState({ postContactUs: {} });
      }, 0)
    } else if (responseJson && responseJson.error) {
      this.setState({
        errorMsg: responseJson.error,
      });
    }
  }

  countryCodeApiResponse = (responseJson: ContryCodeResponse) => {
    if (responseJson && !responseJson.error) {
      this.setState({ countryCodeData: responseJson })
    } else if (responseJson && responseJson.error) {
      this.setState({
        errorMsg: responseJson.error
      });
    }
  }

  getContactUsApiResponse = (responseJson: GetContactUS) => {
    if (responseJson && !responseJson.error) {
      this.setState({ getContactUs: responseJson })
    } else if (responseJson && responseJson.error) {
      this.setState({
        errorMsg: responseJson.error
      });
    }
  }

  getContactUsApiCall = () => {
    const lang = this.state.selectedLanguage === "English" ? "english" : "arabic";

    const headers = {
      "Content-Type": "application/json",
    };
    const getMsgForContactus = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getContactUsApiCallId = getMsgForContactus.messageId;
    getMsgForContactus.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getContactTitleDescEndPoint}?language=${lang}`
    );
    getMsgForContactus.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getMsgForContactus.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(getMsgForContactus.id, getMsgForContactus);
  }

  getCountryCodeApiCall = () => {
    const headers = {
      "Content-Type": "application/json",
    };
    const getMsgForCountryCode = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCountryCodeApiCallId = getMsgForCountryCode.messageId;
    getMsgForCountryCode.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCountryCodeApiEndPoint}`
    );
    getMsgForCountryCode.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getMsgForCountryCode.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(getMsgForCountryCode.id, getMsgForCountryCode);
  }

  postContactUsApiCall = () => {
    const headers = {
      token: this.state.token,
      "Content-Type": "application/json",
    };

    const body = {
      "contact_us": {
        "first_name": this.state.firstName,
        "last_name": this.state.lastName,
        "email": this.state.userEmail,
        "mobile_number": this.state.userPhoneNumber,
        "country_code": this.state.countryCode,
        "message": this.state.message,
        "privacy_policy_agreement": this.state.privacyCheck
      }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.postContactUsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.postContactUSApiEndpoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleSendMessage = () => {
    const { firstNameError, lastNameError, messageError, emailError, phoneNumberError } = this.state;
    this.setState({ sendMessageClicked: true })
    const errorInFirstName = !this.state.firstName;
    const errorInLastName = !this.state.lastName;
    const errorInUserEmail = !this.state.userEmail;
    const errorInMobileNumber = !this.state.userPhoneNumber;
    const errorInMessage = !this.state.message;
    const errorInPrivacyCheck = !this.state.privacyCheck;

    let emailError1 = "";
    const emailRegex = configJSON.emailRegEx;

    if (errorInUserEmail) {
      emailError1 = this.requireMessage();
    } else if (!emailRegex.test(this.state.userEmail)) {
      emailError1 = this.state.selectedLanguage.toLowerCase() === "english" ?
        configJSON.validEmailErrorMsg :
        configJSON.validEmailErrorMsgArabic;
    }
    this.setState({ emailError: emailError1 })

    this.validationMessage();
    this.validationFirstName();
    this.validationLastName();
    this.validationUserPhone();

    if (errorInFirstName || firstNameError || errorInLastName || lastNameError ||
      errorInUserEmail || emailError || errorInMobileNumber || phoneNumberError ||
      errorInMessage || messageError || errorInPrivacyCheck) {
      return;
    }

    this.setState({ emailError: "", messageError: "", phoneNumberError: "", firstNameError: "", lastNameError: "" })
    this.postContactUsApiCall();
  }

  requireMessage = () => {
    return this.state.selectedLanguage.toLowerCase() === "english" ?
      configJSON.requiredErrorMsg :
      configJSON.requiredErrorMsgArabic
  }

  validationEmail = () => {
    const errorInUserEmail = !this.state.userEmail;
    let emailError = "";
    const emailRegex = configJSON.emailRegEx;

    if (errorInUserEmail) {
      emailError = this.requireMessage();
    } else if (!emailRegex.test(this.state.userEmail)) {
      emailError = this.state.selectedLanguage.toLowerCase() === "english" ?
        configJSON.validEmailErrorMsg :
        configJSON.validEmailErrorMsgArabic;
    }
    this.setState({ emailError })
  }

  validationMessage = () => {
    let messageError = "";
    if (this.state.message.length > 500) {
      messageError = this.state.selectedLanguage.toLowerCase() === "english" ?
        configJSON.messageError :
        configJSON.messageErrorArabic;
    }
    this.setState({ messageError });
  }

  validationFirstName = () => {
    let firstNameError = "";
    if (this.state.firstName.length > 100) {
      firstNameError = this.state.selectedLanguage.toLowerCase() === "english" ?
        configJSON.firstNameError :
        configJSON.firstNameErrorArabic;
    }
    this.setState({ firstNameError })
  }

  validationLastName = () => {
    let lastNameError = "";
    if (this.state.lastName.length > 100) {
      lastNameError = this.state.selectedLanguage.toLowerCase() === "english" ?
        configJSON.lastNameError :
        configJSON.lastNameErrorArabic;
    }
    this.setState({ lastNameError })
  }

  validationUserPhone = () => {
    const phoneNumberErrorText = this.state.selectedLanguage.toLowerCase() === "english" ?
      configJSON.phoneNumberErrorMsg :
      configJSON.phoneNumberErrorMsgArabic;

    const phoneNumberError = this.state.userPhoneNumber && !/^\d+$/.test(this.state.userPhoneNumber)
      ? phoneNumberErrorText
      : "";

    this.setState({ phoneNumberError });
  }

  txtNameProps = {
    onChangeText: (text: string) => {
      this.setState({ name: text });

      //@ts-ignore
      this.txtNameProps.value = text;
    },
  };

  txtEmailProps = {
    onChangeText: (text: string) => {
      this.setState({ email: text });

      //@ts-ignore
      this.txtEmailProps.value = text;
    },
  };
  txtPhoneNumberProps = {
    onChangeText: (text: string) => {
      this.setState({ phoneNumber: text });

      //@ts-ignore
      this.txtPhoneNumberProps.value = text;
    },
    // keyboardType: "phone-pad"
  };
  txtCommentsProps = {
    multiline: true,
    onChangeText: (text: string) => {
      this.setState({ comments: text });

      //@ts-ignore
      this.txtCommentsProps.value = text;
    },
  };

  setName = (text: string) => {
    this.setState({ name: text });
  };

  setEmail = (text: string) => {
    this.setState({ email: text });
  };

  setPhoneNumber = (text: string) => {
    this.setState({ phoneNumber: text });
  };

  setComments = (text: string) => {
    this.setState({ comments: text });
  };

  addQuery = () => {
    this.props.navigation.navigate("AddContactus");
  };

  hideModal = () => {
    this.setState({ isVisible: !this.state.isVisible });
  };

  setModal = (item: any) => {
    this.setState({
      activeId: item.id,
      activeName: item.attributes.name,
      activeEmail: item.attributes.email,
      activeDescription: item.attributes.description,
      activePhoneNumber: item.attributes.phone_number,
      activeCreatedAt: item.attributes.created_at,
      isVisible: !this.state.isVisible,
    });
  };

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail = (Email: string) => {
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (reg.test(Email) === false) {
      return false;
    } else {
      return true;
    }
  };

  addQueryApi = () => {
    if (
      this.isStringNullOrBlank(this.state.name) ||
      this.isStringNullOrBlank(this.state.email) ||
      this.isStringNullOrBlank(this.state.phoneNumber) ||
      this.isStringNullOrBlank(this.state.comments)
    ) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    } else if (!this.isValidEmail(this.state.email.trim())) {
      this.showAlert(configJSON.errorTitle, configJSON.errorEmailNotValid);
      return false;
    } else {
      let data = {
        data: {
          name: this.state.name.trim(),
          email: this.state.email.trim(),
          phone_number: this.state.phoneNumber.trim(),
          description: this.state.comments.trim(),
        },
      };

      const header = {
        "Content-Type": configJSON.contactUsApiContentType,
        token: this.state.token,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.addContactApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getContactUsAPiEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(data)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  };

  deleteContactUs = (id: number) => {
    const header = {
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteContactApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getContactUsAPiEndPoint + `/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getContactUsList = (token: string) => {
    const header = {
      "Content-Type": configJSON.contactUsApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.contactUsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getContactUsAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  btnSubmitProps = {
    onPress: () => this.addQueryApi(),
  };

  btnBackProps = {
    onPress: () => this.props.navigation.goBack(),
  };
  // Customizable Area End
}
