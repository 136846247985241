import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { SelectChangeEvent } from '@mui/material/Select';
interface UserData {
    profile: string;
    firstName: string;
    lastName: string;
    email: string;
    contact: string;
    designation: string;
    department: string;
}
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    selctedTab: string;
    selectedFilter: string;
    userFilter: string[];
    selectedStatus: string;
    userStatus: string[];
    userData: UserData[];
    // Customizable Area End

}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class AllUsersDashboardController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];

        this.state = {
            selctedTab: "candidate",
            selectedFilter: "",
            userFilter: ["All Active Users", "All Inactive users", "Sort by Name"],
            selectedStatus: "",
            userStatus: ["Active", "Inactive"],
            userData: [
                { profile: 'Cameron', firstName: 'Cameron', lastName: 'Doe', email: 'Cameron.Doe@gmail.com', contact: '+91 9900000000', designation: 'Android Developer', department: 'Development' },
                { profile: 'Cameron', firstName: 'Cameron', lastName: 'Doe', email: 'Cameron.Doe@gmail.com', contact: '+91 9900000000', designation: 'Android Developer', department: 'Development' },
                { profile: 'Cameron', firstName: 'Cameron', lastName: 'Doe', email: 'Cameron.Doe@gmail.com', contact: '+91 9900000000', designation: 'Android Developer', department: 'Development' },
                { profile: 'Cameron', firstName: 'Cameron', lastName: 'Doe', email: 'Cameron.Doe@gmail.com', contact: '+91 9900000000', designation: 'Android Developer', department: 'Development' },
            ]
        };

        // Customizable Area End
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();

    }

    handleTabClick = (tabName: string) => {
        this.setState({ selctedTab: tabName })
    }

    handleFilter = (event: SelectChangeEvent<string>) => {
        this.setState({ selectedFilter: event.target.value });
    }

    handleStatus = (event: SelectChangeEvent<string>) => {
        this.setState({ selectedStatus: event.target.value });
    }

    navigateToCreateUser = () => {
        const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
        navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "UserCreation");
        navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigateMsg);
    }

    // Customizable Area End

}