import React from "react";
// Customizable Area Start
import {
    Box,
    Grid,
    styled,
    Typography,
    Button,
    CardContent,
    Card,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    IconButton,
    TextField,
    InputAdornment,
    Pagination,
    Select,
    InputBase,
    MenuItem
} from "@mui/material";
import { emptyDashboardImg, defaultUserImg } from "./assets";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DashboardHeader from "../../dashboard/src/DashboardHeader.web";
import SearchIcon from '@mui/icons-material/Search';
import MoreVertIcon from '@mui/icons-material/MoreVert';
// Customizable Area End

import AllUsersDashboardController, {
    Props
} from "./AllUsersDashboardController";

export default class AllUsersDashboard extends AllUsersDashboardController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    noDataScreen = () => {
        return (
            <EmptyWrapper>
                <Grid container className="gridContainer">
                    <Grid item xs={12}>
                        <Box className="noDataImageBox">
                            <img src={emptyDashboardImg.default} />
                            <Typography className="noDataMainText">Ohh No, No data Found</Typography>
                            <Typography className="noDataNextText">It looks like there’s nothing here yet. Add New User for greater efficiency and growth.</Typography>
                            <Button className="addNewUserButton" onClick={this.navigateToCreateUser}>
                                <Typography className="addNewUserText">Add New User</Typography>
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </EmptyWrapper>
        )
    }

    allUsersDashboard = () => {
        return (
            <Wrapper>
                <Card sx={{ borderRadius: 2, boxShadow: "rgba(0, 0, 0, 0.1) 0px 14px 144px 0px" }}>
                    <CardContent>
                        <Grid container spacing={2} alignItems="center" justifyContent="space-between">

                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={4} xl={4}>
                                        <Button
                                            data-test-id="candidate_tab"
                                            className={this.state.selctedTab === 'candidate' ? "selectedUsersButton" : "usersButton"}
                                            onClick={() => this.handleTabClick('candidate')}
                                        >
                                            Candidates (85)
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} md={4} xl={4}>
                                        <Button
                                            data-test-id="employee_tab"
                                            className={this.state.selctedTab === 'employee' ? "selectedUsersButton" : "usersButton"}
                                            onClick={() => this.handleTabClick('employee')}
                                        >
                                            Employees (120)
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} md={4} xl={4}>
                                        <Button
                                            data-test-id="hrSubAdmin_tab"
                                            className={this.state.selctedTab === 'hrSubAdmin' ? "selectedUsersButton" : "usersButton"}
                                            onClick={() => this.handleTabClick('hrSubAdmin')}
                                        >
                                            HR SubAdmin (20)
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <Grid container spacing={2}
                                    sx={{
                                        justifyContent: {
                                            xs: "flex-start",
                                            md: "flex-end"
                                        }
                                    }}>
                                    <Grid item xs={12} md={4} xl={4.5} >
                                        <TextField
                                            className="searchBox"
                                            placeholder="Search"
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton>
                                                            <SearchIcon />
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4} xl={3.5}>
                                        <Select
                                            data-test-id="user_filter"
                                            className="filterBox"
                                            inputProps={{
                                                icon: {
                                                    up: 0,
                                                },
                                            }}
                                            displayEmpty
                                            variant="outlined"
                                            value={this.state.selectedFilter}
                                            renderValue={
                                                this.state.selectedFilter
                                                    ? undefined
                                                    : () => <div className="dropDownStyleplaceHolder">Filter</div>
                                            }
                                            IconComponent={KeyboardArrowDownIcon}
                                            onChange={this.handleFilter}
                                            input={<InputBase />}
                                        >
                                            {this.state.userFilter.map((option) => (
                                                <MenuItem key={option} value={option}
                                                    sx={{
                                                        background: `${option === "All Active Users" ? "#f1f5f9" : "none"}`,
                                                        fontFamily: "Urbanist",
                                                        fontSize: "14px",
                                                        fontWeight: 400,
                                                        margin: "4px"
                                                    }}>
                                                    {option}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </Grid>
                                    <Grid item xs={12} md={4} xl={3.5}>
                                        <Button className="addUserButton" data-test-id="add_new_user" onClick={this.navigateToCreateUser}>
                                            <Typography className="addUserText">
                                                Add New User
                                            </Typography>
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <TableContainer sx={{ mt: 2 }}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className="tableTilteText">Profile</TableCell>
                                        <TableCell className="tableTilteText">First Name</TableCell>
                                        <TableCell className="tableTilteText">Last Name</TableCell>
                                        <TableCell className="tableTilteText">Email</TableCell>
                                        <TableCell className="tableTilteText">Contact Number</TableCell>
                                        <TableCell className="tableTilteText">Designation</TableCell>
                                        <TableCell className="tableTilteText">Department</TableCell>
                                        <TableCell className="tableTilteText">Status</TableCell>
                                        <TableCell className="tableTilteText">Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.userData.map((row, index) => (
                                        <TableRow key={index}>
                                            <TableCell>
                                                <Box className="profilePicBox">
                                                    <img src={defaultUserImg.default} alt="Profile pic" className="tableProfilePic" />
                                                </Box>
                                            </TableCell>
                                            <TableCell className="tableDataText">{row.firstName}</TableCell>
                                            <TableCell className="tableDataText">{row.lastName}</TableCell>
                                            <TableCell className="tableDataText">{row.email}</TableCell>
                                            <TableCell className="tableDataText">{row.contact}</TableCell>
                                            <TableCell className="tableDataText">{row.designation}</TableCell>

                                            <TableCell className="tableDataText">{row.department}</TableCell>
                                            <TableCell className="tableDataText">
                                                <Select
                                                    displayEmpty
                                                    variant="outlined"
                                                    data-test-id="user_status"
                                                    className="statusRow"
                                                    value={this.state.selectedStatus}
                                                    renderValue={
                                                        this.state.selectedStatus
                                                            ? undefined
                                                            : () => <div className="statusDropDown">Active</div>
                                                    }
                                                    IconComponent={KeyboardArrowDownIcon}
                                                    onChange={this.handleStatus}
                                                    input={<InputBase />}
                                                >
                                                    {this.state.userStatus.map((status) => (
                                                        <MenuItem key={status} value={status}
                                                            sx={{
                                                                background: `${status === "Active" ? "#f1f5f9" : "none"}`,
                                                                fontFamily: "Urbanist",
                                                                fontSize: "14px",
                                                                fontWeight: 400,
                                                                margin: "4px"
                                                            }}>
                                                            {status}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </TableCell>
                                            <TableCell>
                                                <IconButton>
                                                    <MoreVertIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                            <Pagination count={8} color="primary" />
                        </Box>
                    </CardContent>
                </Card>
            </Wrapper>
        )
    }
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <DashboardHeader selectedMenu="All users" id={this.props.id} navigation={this.props.navigation}>
                <MainWrapper>
                    <Grid container justifyContent={"center"}>
                        <Grid item xs={11}>
                            {this.state.userData.length ? this.allUsersDashboard() : this.noDataScreen()}
                        </Grid>
                    </Grid>
                </MainWrapper>
            </DashboardHeader>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export const Wrapper = styled(Box)({
    width: "100%",
    "& .usersButton": {
        fontSize: "18px",
        fontWeight: 700,
        lineHeight: "26.46px",
        color: "#64748B",
        textTransform: "none",
        padding: "8px",
        paddingLeft: 0
    },
    "& .tableProfilePic": {
        width: "30px",
        height: "30px"
    },
    "& .profilePicBox": {
        height: "56px",
        width: "56px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: '50%',
        border: "1px solid #F0E5FF",
        background: "#CBD5E1"
    },
    "& .selectedUsersButton": {
        paddingLeft: 0,
        fontSize: "18px",
        fontWeight: 700,
        lineHeight: "26.46px",
        color: "#64748B",
        textTransform: "none",
        padding: "8px",
        borderRadius: "4px",
        border: "1px solid #64748B",
        background: "#F1F5F9",
    },
    "& .searchBox": {
        width: "100%",
        height: "58px",
        borderRadius: "4px",
        fontFamily: "Urbanist",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px",
        color: "#6C7B89",
        "&:hover": {
            borderColor: "none"
        },
    },
    "& .MuiSelect-icon": {
        transform: 'none !important',
    },
    "& .css-1uwzc1h-MuiSelect-select-MuiInputBase-input": {
        paddingRight: "8px !important"
    },
    "& .statusDropDown": {
        fontFamily: "Urbanist",
        fontSize: "12px",
        fontWeight: 700,
        lineHeight: "18px",
        color: "#64748B",
        marginTop: "3px"
    },
    "& .statusRow": {
        width: "100px",
        height: "30px",
        borderRadius: "4px",
        padding: "0px 20px 0px 16px",
        textTransform: "none",
        border: "1px solid #CBD5E1",
        color: "#64748B",
        fontFamily: "Urbanist",
        fontSize: "12px",
        fontWeight: 700,
        lineHeight: "18px",
        marginTop: "3px"
    },
    "& .filterBox": {
        width: "100%",
        height: "55px",
        borderRadius: "4px",
        padding: "0px 20px 0px 16px",
        textTransform: "none",
        background: "#F1F5F9",
        border: "1px solid #CBD5E1",
        color: "#64748B"
    },
    "& .filterText": {
        fontFamily: "Urbanist",
        fontWeight: 700,
        fontSize: "16px",
        color: "#64748B"
    },
    "& .addUserButton": {
        width: "100%",
        height: "58px",
        borderRadius: "4px",
        background: "#044352",
    },
    "& .addUserText": {
        fontFamily: "Urbanist",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "24px",
        color: "#FFFFFF",
        textTransform: "none"
    },
    "& .tableTilteText": {
        fontFamily: "Urbanist",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "24px",
        color: "#044352"
    },
    "& .tableDataText": {
        fontFamily: "Urbanist",
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "24px",
        color: "#044352"
    }
})
export const EmptyWrapper = styled(Box)({
    width: "100%",
    paddingBottom: "30px",
    height: "calc(100vh - 180px)",
    "& .addNewUserButton": {
        padding: "8px 60px",
        marginTop: "24px",
        height: "58px",
        borderRadius: "4px",
        background: "#044352",
    },
    "& .gridContainer": {
        height: "100%"
    },
    "& .noDataNextText": {
        width: "40%",
        fontWeight: 400,
        color: "#8A96A8",
        fontFamily: "Urbanist",
        fontSize: "18px",
        lineHeight: "26px",
        textAlign: "center"
    },
    "& .addNewUserText": {
        fontWeight: 700,
        fontFamily: "Urbanist",
        fontSize: "16px",
        lineHeight: "24px",
        color: "#FFFFFF",
        textTransform: "none"
    },
    "& .noDataMainText": {
        color: "#000104",
        fontFamily: "Urbanist",
        fontWeight: 700,
        fontSize: "30px",
        lineHeight: "40px",        
    },
    "& .noDataImageBox": {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },    
})
export const MainWrapper = styled(Box)({
    marginTop: "42px",
    paddingBottom: "30px",
    display: "flex",
    justifyContent: "center",
    width: "100%",
    "& .compMainHeaderGrid": {
        marginTop: "20px"
    },
})
// Customizable Area End