import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getStorageData } from "../../../framework/src/Utilities";
import { SelectChangeEvent } from '@mui/material/Select';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  i18n?:any;
  // Customizable Area End
}

interface S {
  // Customizable Area Star
  token: string;
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  selectedLanguage:string;
  jobLevelValue : string;
  jobTitle:string;
  department:string;
  jobLevel:{id:number, name: string}[];
  jdstyle:{id:number, name: string}[];
  jobFamily:{id:number, name: string}[];
  jobSummary:string;
  keySkills:{id:number, name: string}[];
  keyWords:{keywords: string}[];
  active: number;
  progressScreen:boolean;
  progress:number;
  progressOpen:boolean;
  jobdescResponse:string;
  emptyscreen:boolean;
  formscreen:boolean;
  jobDetailScreen:boolean;
  selectedOption:string;
  editJobDetailScreen:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Cfchatgptintegration9Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  createJobDescApiCallId:string="";
  getJobDescLevelApiCallId:string="";
  getJobDescExpApiCallId:string="";
  getJobDescKeySkillApiCallId:string="";
  getJobDescKeyWordApiCallId:string="";
  getJobDescJobFamilyApiCallId:string="";
  interval: number | undefined;
  intervals:number | undefined;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage)
      // Customizable Area End
    ];

    this.state = {
      token: "",
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      selectedLanguage:"",
      jobLevelValue:"",
      jobTitle:"",
      department:"",
      jobSummary:"",
      jobLevel:[],
      jdstyle:[],
      jobFamily:[],
      keySkills:[],
      keyWords:[],
      active: 0,
      progressScreen:false,
      progress:0,
      progressOpen:false,
      jobdescResponse:"",
      emptyscreen:true,
      formscreen:false,
      jobDetailScreen:false,
      selectedOption:"",
      editJobDetailScreen:false,
      // Customizable Area Start
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      runEngine.debugLog("API Message Recived", message);
      if (apiRequestCallId === this.getJobDescLevelApiCallId) {
        this.setState({jobLevel:responseJson})
      }
      if (apiRequestCallId === this.getJobDescExpApiCallId) {
        this.setState({jdstyle:responseJson})
      }
      if (apiRequestCallId === this.getJobDescKeySkillApiCallId) {
        const keySkillData = responseJson.data.map((data:any) => ({
          id: data.attributes.id,
          name: data.attributes.skill_name,
      }));
      this.setState({ keySkills: keySkillData });
      }
      if (apiRequestCallId === this.getJobDescKeyWordApiCallId) {
        const keyWordsData = responseJson.keywords.map((data:any) => ({
          keywords: data,
      }));
      this.setState({ keyWords: keyWordsData });
      }
      if (apiRequestCallId === this.createJobDescApiCallId) {
        this.setState({formscreen: false , progressScreen:true, jobdescResponse:responseJson.message})
      }
      if (apiRequestCallId === this.getJobDescJobFamilyApiCallId) {
        this.setState({jobFamily:responseJson})
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  async componentDidMount() {
    const tokenMeta = await getStorageData('signInResponse', true);
    this.setState({
      token: tokenMeta?.meta?.token
    })
    this.jobDescriptionGetCall()
    this.jobDescriptionExpGetCall()
    this.jobDescriptionKeySkillGetCall()
    this.jobDescriptionKeyWordGetCall()
    this.jobDescriptionJobFamilyGetCall()
  }
 
  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined) {
    if(prevState.progressScreen !== this.state.progressScreen) {
    if(this.state.progressScreen){
      this.interval = setInterval(() => {
        if(this.state.active === 6) {
          this.setState({active: 0})
        }else
        this.setState({active: this.state.active + 1})
      }, 300) as unknown as number

      
      this.intervals = setInterval(() => {
        if(this.state.progress >= 100){
          clearInterval(this.interval);
          clearInterval(this.intervals);
          this.setState({progress : 100});
          this.setState({progressOpen : true});
        }
        this.setState({progress : this.state.progress + 1})
      },100) as unknown as number
    }
  }
  }

  async componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = undefined;
    }
    console.log(this.intervals, 'interval')
    if (this.intervals) {
      clearInterval(this.intervals);
      this.intervals = undefined;
    }
  }

  jobDescriptionCall = async(data:any) => {
    
    const headers = {
      "token": this.state.token,
      "Content-Type": configJSON.validationApiContentType,
    };
    const httpBody: any = {
        "job_description": {
          "job_title": data.jobTitle,
          "department": data.DepartmentTeam,
          "job_level": data.jobLevel,
          "job_family": data.jobFamily,
          "job_experience": data.jdStyle,
          "job_summary": data.jobSummary,
          "key_skills": data.keySkills,
          "key_words": data.keyWords
        }
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createJobDescApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createJobDescApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.POST
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  jobDescriptionGetCall = () => {
    
    const headers = {
      "token": this.state.token,
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getJobDescLevelApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getJobDescLevelApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  jobDescriptionExpGetCall = () => {
    
    const headers = {
      "token": this.state.token,
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getJobDescExpApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getJobDescExpApiEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  jobDescriptionKeySkillGetCall = () => {
    
    const headers = {
      "token": this.state.token,
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getJobDescKeySkillApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getJobDescKeySkillApiEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  jobDescriptionKeyWordGetCall = () => {
    
    const headers = {
      "token": this.state.token,
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getJobDescKeyWordApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getJobDescKeyWordApiEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  jobDescriptionJobFamilyGetCall = () => {
    
    const headers = {
      "token": this.state.token,
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getJobDescJobFamilyApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getJobDescJobFamilyAPiEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  closeProgressModal = () => {
    this.setState({progressOpen: false, progressScreen: false, formscreen:true ,jobDetailScreen:false  });
  };

  openProgressModal = () => {
    this.setState({ progressOpen: false, progressScreen:false , jobDetailScreen:true });
  };

  handleCondition = (condition: any, truePart: any, falsePart: any) => {
    return condition ? truePart : falsePart
  }

  handleTrueCondition = (condition: any, truePart: any) => {
    return condition && truePart
  }

  backToForm = () => {
    this.setState({formscreen: true , progressScreen:false, progress:0})
    clearInterval(this.intervals);
  }

  createNewJob = () => {
    this.setState({formscreen: true , emptyscreen:false})
  }
  
  navigateToDashboard = () => {
    const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
    navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "Dashboard");
    navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigateMsg);    
  }

  handleChange = (event:SelectChangeEvent<string>) => {
    this.setState({ selectedOption: event.target.value });
  };
   // Customizable Area End
}