export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const profilePic = require("../assets/profilePic.png");
export const emailImage = require("../assets/emailImage.png");
export const copyImage = require("../assets/copyImage.png");
export const rightArrow = require("../assets/rightArrow.png");
export const companyImage = require("../assets/companyImage.png");
export const linkedinImage = require("../assets/linkedinImage.png");
export const twitterImage = require("../assets/twitterImage.png");
export const boxImage = require("../assets/boxImage.png");
export const viewBLeft = require("../assets/viewBLeft.png");
export const viewB = require("../assets/viewB.png");
export const calender = require("../assets/calender.png");
export const camera = require("../assets/camera.png");
export const userDefaultImage = require("../assets/userDefaultImage.png");
export const uploadImg = require("../assets/uploadImg.png");
export const removeImg = require("../assets/removeImg.png");
export const successImg = require("../assets/successImg.png");
export const backArrow = require("../assets/backArrow.svg");
export const uploadImage = require("../assets/uploadImage.svg");
export const more = require("../assets/more.svg");
export const uploadedFile = require("../assets/uploadedFile.png");
export const emptyDashboardImg = require("../assets/emptyDashboardImg.png");
export const defaultUserImg = require("../assets/defaultUserImg.png")
export const userNameImg = require("../assets/userNameImg.svg")
export const emailImg = require("../assets/emailImg.svg")
export const contactImg = require("../assets/contactImg.svg")
export const birthDateImg = require("../assets/birthDateImg.svg")
export const genderImg = require("../assets/genderImg.svg")
export const companyImg = require("../assets/companyImg.svg")
export const competenciesImg = require("../assets/competenciesImg.svg")
